import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ConstantsService } from '../../services/utils/constants.service';
import { GatewayFirmwareService } from '../../services/gateway-firmware/gateway-firmware.service';
import { ValidationService } from '../../services/utils/validation.service';
import Swal from 'sweetalert2';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-gateway-firmware',
    templateUrl: './gateway-firmware.component.html',
    styleUrls: ['./gateway-firmware.component.css']
})
export class GatewayFirmwareComponent implements OnInit {

    public version: string = '';
    public firmware: string = '';
    private file: File = null;
    private readonly FILE_PLACEHOLDER: string = 'Choose file';

    private readonly SPAN_NORMAL_VERSION: string = 'Please enter version';
    private readonly SPAN_DANGER_VERSION: string = 'Version is not correct!';

    public span_version: string;

    public isDeveloper: boolean = false;
    public isSuper: boolean = false;
    public isAdmin: boolean = false;

    @ViewChildren('ngForRendred') ngForList: QueryList<any>;

    constructor(private constantsService: ConstantsService, private gatewayFirmwareService: GatewayFirmwareService,
        private validationService: ValidationService) { }

    ngOnInit() {
        this.firmware = this.FILE_PLACEHOLDER;

        mApp.blockPage();
        this.clearWarnings();

        this.gatewayFirmwareService.getFirmwareVersion().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let current_version = response.value;
                this.version = current_version;
                mApp.unblockPage();
            },
            (err) => {
                // if reached here, it means the response is error
                mApp.unblockPage();
            });
    }

    private validate(firmware: any): boolean {
        let validation: boolean = true;
        // Version
        if (!(this.validationService.validateNonEmpty(firmware.version))) {
            validation = false;
            this.versionWarnings();
        }
        return validation;
    }

    public submit(): void {
        this.clearWarnings();
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);
        let firmware = {
            version: this.version
        }

        if (this.validate(firmware)) {
            // post the user
            this.gatewayFirmwareService.postFirmware(firmware, this.file).subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.cancel();
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                },
                (err) => {
                    Swal(
                        'Oops...',
                        err._body,
                        'error'
                    );
                    // if reached here, it means the response is error
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                });
        } else {
            btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
        }
    }

    public cancel(): void {
        this.version = '';
        this.file = null;
        this.firmware = this.FILE_PLACEHOLDER;

        this.clearWarnings();
    }

    public fileChange(event) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.file = fileList[0];
            this.firmware = fileList[0].name;
        }
    }

    private clearWarnings(): void {
        this.span_version = this.SPAN_NORMAL_VERSION;

        $('#versionspan').removeClass();
        $('#versionspan').addClass('m-form__help');
        $('#versionlabel').removeClass();
    }

    private versionWarnings(): void {
        this.span_version = this.SPAN_DANGER_VERSION;
        $('#versionspan').removeClass();
        $('#versionspan').addClass('m-form__help m--font-danger');
        $('#versionlabel').removeClass();
        $('#versionlabel').addClass('m--font-danger');
    }

}
