import { Routes, RouterModule } from '@angular/router';
import { LoginGuardDeveloperService } from './services/login-guard/login-guard-developer.service';
import { LoginGuardSuperService } from './services/login-guard/login-guard-super.service';
import { LoginGuardAdminService } from './services/login-guard/login-guard-admin.service';
import { LoginGuardUserService } from './services/login-guard/login-guard-user.service';
import { LoginGuardAllService } from './services/login-guard/login-guard-all.service';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { ErrorComponent } from './components/error/error.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserAddComponent } from './components/user-add/user-add.component';
import { UserManageComponent } from './components/user-manage/user-manage.component';
import { ClientAddComponent } from './components/client-add/client-add.component';
import { ClientManageComponent } from './components/client-manage/client-manage.component';
import { ApartmentManageComponent } from './components/apartment-manage/apartment-manage.component';
import { ApartmentAddComponent } from './components/apartment-add/apartment-add.component';
import { RoomManageComponent } from './components/room-manage/room-manage.component';
import { RoomAddComponent } from './components/room-add/room-add.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SupportComponent } from './components/support/support.component';
import { FaqComponent } from './components/faq/faq.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { GatewayFirmwareComponent } from './components/gateway-firmware/gateway-firmware.component';
import { GatewayComponent } from './components/gateway/gateway.component';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    {
        path: 'home',
        canActivate: [LoginGuardAllService],
        component: HomeComponent,
        children: [
            {
                path: '',
                component: DashboardComponent,
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                pathMatch: 'full'
            },
            {
                path: 'profile',
                component: ProfileComponent,
                canActivate: [LoginGuardUserService],
                pathMatch: 'full'
            },
            {
                path: 'support',
                component: SupportComponent,
                canActivate: [LoginGuardUserService],
                pathMatch: 'full'
            },
            {
                path: 'faq',
                component: FaqComponent,
                canActivate: [LoginGuardUserService],
                pathMatch: 'full'
            },
            {
                path: 'privacy',
                component: PrivacyComponent,
                canActivate: [LoginGuardUserService],
                pathMatch: 'full'
            },
            {
                path: 'user/add',
                component: UserAddComponent,
                canActivate: [LoginGuardAdminService],
                pathMatch: 'full'
            },
            {
                path: 'user/manage',
                component: UserManageComponent,
                canActivate: [LoginGuardAdminService],
                pathMatch: 'full'
            },
            {
                path: 'client/add',
                component: ClientAddComponent,
                canActivate: [LoginGuardSuperService],
                pathMatch: 'full'
            },
            {
                path: 'client/manage',
                component: ClientManageComponent,
                canActivate: [LoginGuardSuperService],
                pathMatch: 'full'
            },
            {
                path: 'apartment/manage',
                component: ApartmentManageComponent,
                canActivate: [LoginGuardUserService],
                pathMatch: 'full'
            },
            {
                path: 'apartment/add',
                component: ApartmentAddComponent,
                canActivate: [LoginGuardAdminService],
                pathMatch: 'full'
            },
            {
                path: 'room/manage',
                component: RoomManageComponent,
                canActivate: [LoginGuardUserService],
                pathMatch: 'full'
            },
            {
                path: 'room/add',
                component: RoomAddComponent,
                canActivate: [LoginGuardAdminService],
                pathMatch: 'full'
            },
            {
                path: 'notifications/manange',
                component: NotificationsComponent,
                canActivate: [LoginGuardUserService],
                pathMatch: 'full'
            },
            {
                path: 'firmware/gateway',
                component: GatewayFirmwareComponent,
                canActivate: [LoginGuardDeveloperService],
                pathMatch: 'full'
            },
            {
                path: 'gateway/manage',
                component: GatewayComponent,
                canActivate: [LoginGuardAdminService],
                pathMatch: 'full'
            }
        ]
    },
    { path: 'home', component: HomeComponent, canActivate: [LoginGuardAllService] },
    { path: 'error', component: ErrorComponent, canActivate: [LoginGuardAllService] },
    { path: '**', redirectTo: '/error', pathMatch: 'full' }
];

export const RoutingModule = RouterModule.forRoot(routes);
