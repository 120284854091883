import { Component, OnInit } from '@angular/core';
import { GatewayService } from '../../services/gateway/gateway.service';
import { UserService } from '../../services/user/user.service';
import { ConstantsService } from '../../services/utils/constants.service';
import Swal from 'sweetalert2';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-gateway',
    templateUrl: './gateway.component.html',
    styleUrls: ['./gateway.component.css']
})
export class GatewayComponent implements OnInit {
    public allGateways: any[] = [];
    private datatable: any;
    public isDeveloper: boolean = false;
    public isSuper: boolean = false;
    public isAdmin: boolean = false;
    public selectedGateways: any[] = [];
    public currentSelectedGateway: any = {};

    constructor(private constantsService: ConstantsService,
        private gatewayService: GatewayService, private userService: UserService) { }

    ngOnInit() {
        mApp.block('#m_table_1', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...'
        });

        this.userService.getMe().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let me = response.value;
                if (me.role == this.constantsService.ROLE_DEVELOPER) {
                    this.isDeveloper = true;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_SUPER_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = false;
                    this.isAdmin = true;
                }
            },
            (err) => {
                // if reached here, it means the response is error
            });

        this.getAllGateways();
    }

    getAllGateways(): void {
        this.gatewayService.getAllGateways().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allGateways = response.value;
                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            },
            (err) => {
                // if reached here, it means the response is error
                this.allGateways = [];
                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            });
    }

    InitTable(): void {
        let self = this;
        // begin first table
        this.datatable = $('#m_table_1').DataTable({
            data: this.allGateways,
            responsive: true,
            paging: true,
            //== DOM Layout settings
            dom: "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                'lengthMenu': 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            headerCallback: function(thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                        <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                            <input type="checkbox" value="" class="m-group-checkable">
                            <span></span>
                        </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    data: "id",
                    width: '30px',
                    className: 'dt-right',
                    orderable: false,
                    render: function(data, type, full, meta) {
                        return `
                            <label id="` + full.id + `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                                <input type="checkbox" value="" class="m-checkable">
                                <span></span>
                            </label>`;
                    },
                },
                {
                    targets: 1,
                    data: "name",
                    title: "Name",
                    className: "text-center"
                },
                {
                    targets: 2,
                    data: "mac_address",
                    title: "Mac Address",
                    className: "text-center"
                },
                {
                    targets: 3,
                    data: "apartment",
                    title: "Apartment",
                    className: "text-center"
                },
                {
                    targets: 4,
                    data: "fw_version",
                    title: "Version",
                    className: "text-center"
                },
                {
                    targets: 5,
                    data: "ble_interface_fw_version",
                    title: "BLE Version",
                    className: "text-center"
                },
                {
                    targets: 6,
                    data: "client",
                    title: "Client",
                    className: "text-center"
                },
                {
                    targets: 7,
                    data: "state",
                    title: "State",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'Online', 'class': 'm-badge--success' },
                            1: { 'title': 'Offline', 'class': ' m-badge--danger' },
                            2: { 'title': 'Unknown', 'class': ' m-badge--metal' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;;
                        if (data == self.constantsService.GATEWAY_STATE_ONLINE) {
                            location = 0;
                        } else if (data == self.constantsService.GATEWAY_STATE_OFFLINE) {
                            location = 1;
                        } else {
                            location = 2;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 8,
                    data: "state_update_timestamp",
                    title: "State timestamp",
                    render: function(data, type, full, meta) {
                        let dateISOString = data
                        let date = new Date(dateISOString);
                        date = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
                        // let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                        let options = {};
                        return '<span>' + date.toLocaleString([], options) + '</span>';
                    },
                },
                {
                    targets: 9,
                    width: '100px',
                    title: 'Actions',
                    orderable: false,
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        return `
                            <a href="javascript:void(0);" id="`+ full.id + `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
                              <i class="la la-trash"></i>
                            </a>`;
                    },
                }
            ],
        });

        this.datatable.on('change', '.m-group-checkable', function() {
            var set = $(this).closest('table').find('td:first-child .m-checkable');
            var checked = $(this).is(':checked');

            $(set).each(function() {
                if (checked) {
                    $(this).prop('checked', true);
                    $(this).closest('tr').addClass('active');
                }
                else {
                    $(this).prop('checked', false);
                    $(this).closest('tr').removeClass('active');
                }
            });

            if (checked) {
                for (let i = 0; i < self.allGateways.length; i++) {
                    self.selectedGateways[i] = self.allGateways[i].id;
                }
            } else {
                self.selectedGateways = [];
            }
        });

        this.datatable.on('change', 'tbody tr .m-checkbox', function() {
            let id = $(this).attr('id');

            let isChecked = $(this).parents('tr').find('td:first-child .m-checkable').is(':checked');

            if (isChecked) {
                //return index of element, if not found it will return -1
                let found = $.inArray(id, self.selectedGateways);
                if (found < 0) {
                    // Element was not found, add it.
                    self.selectedGateways.push(id);
                }
            } else {
                // return index of element, if not found it will return -1
                let found = $.inArray(id, self.selectedGateways);
                if (found >= 0) {
                    // Element was found, remove it.
                    self.selectedGateways.splice(found, 1);
                }
            }
        });

        $('#m_table_1').on('click', 'a.m-portlet__nav-link', function() {
            let id = $(this).attr('id');
            self.DeleteGateway(id);
        });
    }

    public DeleteGateway(id: string): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            )

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover gateway again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                self.gatewayService.deleteGatewayId(id).subscribe(
                    (response) => {
                        self.getAllGateways();
                        // Swal(
                        //     'Deleted!',
                        //     'Gateway has been deleted.',
                        //     'success'
                        // );
                    }, (err) => {
                        self.getAllGateways();
                        Swal(
                            'Cancelled',
                            err._body,
                            'error'
                        )
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        })
    }

    public DeleteGateways(): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            )

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover those gateways again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                self.gatewayService.deleteGateways(self.selectedGateways).subscribe(
                    (response) => {
                        self.getAllGateways();
                        // Swal(
                        //     'Deleted!',
                        //     'Gateway has been deleted.',
                        //     'success'
                        // );
                    }, (err) => {
                        self.getAllGateways();
                        Swal(
                            'Cancelled',
                            'Error occured and gateways were not deleted',
                            'error'
                        )
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        })
    }

}
