import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import * as moment from 'moment';
import { ValidationService } from '../../services/utils/validation.service';
import Swal from 'sweetalert2';
import { ConstantsService } from '../../services/utils/constants.service';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-client-manage',
    templateUrl: './client-manage.component.html',
    styleUrls: ['./client-manage.component.css']
})
export class ClientManageComponent implements OnInit, AfterViewInit {

    public allClients: any[] = [];
    private datatable: any;
    public selectedClients: any[] = [];
    public currentSelectedClient: any = {};

    private readonly SPAN_NORMAL_NAME: string = 'Enter client name';
    private readonly SPAN_DANGER_NAME: string = 'Client name canot be empty!';
    private readonly SPAN_NORMAL_ID: string = 'Enter client ID';
    private readonly SPAN_DANGER_ID: string = 'Client ID canot be empty!';
    private readonly SPAN_NORMAL_SECRET: string = 'Enter secret';
    private readonly SPAN_NORMAL_REPEAT_SECRET: string = 'Confirm secret';
    private readonly SPAN_DANGER_SECRET: string = 'Secret should match!';
    private readonly SPAN_NORMAL_EXPIRY_DATE: string = 'Select expiry date';
    private readonly SPAN_DANGER_EXPIRY_DATE: string = 'Date format is not correct!';

    public span_client_name: string;
    public span_client_id: string;
    public span_client_secret: string;
    public repeat_client_secret_span: string;
    public span_repeat_client_secret: string;
    public span_expiry_date: string;

    constructor(private clientService: ClientService, private constantsService: ConstantsService,
        private validationService: ValidationService) { }

    ngOnInit() {
        // minimum setup
        $('#m_touchspin_1').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 10,
            max: 10000,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        // minimum setup
        $('#m_touchspin_2').TouchSpin({
            buttondown_class: 'btn btn-secondary',
            buttonup_class: 'btn btn-secondary',
            min: 30,
            max: 10000,
            step: 1,
            decimals: 0,
            boostat: 5,
            maxboostedstep: 10,
        });

        // input group demo
        $('#m_datetimepicker_2').datetimepicker({
            todayHighlight: true,
            autoclose: true,
            pickerPosition: 'top-right',
            format: 'yyyy/mm/dd',
            minView: 2
        });

        // basic
        $('#m_select2_1').select2({
            placeholder: "Select account",
            allowClear: false,
            width: '100%'
        });

        $('#m_select2_2').select2({
            placeholder: "Select status",
            allowClear: false,
            width: '100%'
        });

        mApp.block('#m_table_1', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...'
        });

        this.clearWarnings();
        this.getAllClients();
    }

    ngAfterViewInit() {
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            },
            (err) => {
                // if reached here, it means the response is error
                this.allClients = [];
                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            });
    }

    private clearWarnings(): void {
        this.span_client_name = this.SPAN_NORMAL_NAME;
        this.span_client_id = this.SPAN_NORMAL_ID;
        this.span_client_secret = this.SPAN_NORMAL_SECRET;
        this.span_repeat_client_secret = this.SPAN_NORMAL_REPEAT_SECRET;
        this.span_expiry_date = this.SPAN_NORMAL_EXPIRY_DATE;

        $('#client_name_span').removeClass();
        $('#client_name_span').addClass('m-form__help');
        $('#clientNamelabel').removeClass();

        $('#client_id_span').removeClass();
        $('#client_id_span').addClass('m-form__help');
        $('#clientIdlabel').removeClass();

        $('#client_secret_span').removeClass();
        $('#client_secret_span').addClass('m-form__help');
        $('#clientSecretlabel').removeClass();

        $('#repeat_client_secret_span').removeClass();
        $('#repeat_client_secret_span').addClass('m-form__help');
        $('#repeatClientSecretlabel').removeClass();

        $('#expiry_date_span').removeClass();
        $('#expiry_date_span').addClass('m-form__help');
        $('#expiryDateLabel').removeClass();
    }

    private clientNameWarnings(): void {
        this.span_client_name = this.SPAN_DANGER_NAME;
        $('#client_name_span').removeClass();
        $('#client_name_span').addClass('m-form__help m--font-danger');
        $('#clientNamelabel').removeClass();
        $('#clientNamelabel').addClass('m--font-danger');
    }

    private clientIdWarnings(): void {
        this.span_client_id = this.SPAN_DANGER_ID;
        $('#client_id_span').removeClass();
        $('#client_id_span').addClass('m-form__help m--font-danger');
        $('#clientIdlabel').removeClass();
        $('#clientIdlabel').addClass('m--font-danger');
    }

    private clientSecretWarnings(): void {
        this.span_client_secret = this.SPAN_DANGER_SECRET;
        $('#client_secret_span').removeClass();
        $('#client_secret_span').addClass('m-form__help m--font-danger');
        $('#clientSecretlabel').removeClass();
        $('#clientSecretlabel').addClass('m--font-danger');
    }

    private clientRepeatSecretWarnings(): void {
        this.span_repeat_client_secret = this.SPAN_DANGER_SECRET;
        $('#repeat_client_secret_span').removeClass();
        $('#repeat_client_secret_span').addClass('m-form__help m--font-danger');
        $('#repeatClientSecretlabel').removeClass();
        $('#repeatClientSecretlabel').addClass('m--font-danger');
    }

    private clientExpiryDateWarnings(): void {
        this.span_expiry_date = this.SPAN_DANGER_EXPIRY_DATE;
        $('#expiry_date_span').removeClass();
        $('#expiry_date_span').addClass('m-form__help m--font-danger');
        $('#expiryDateLabel').removeClass();
        $('#expiryDateLabel').addClass('m--font-danger');
    }

    private validate(client: any): boolean {
        let validation: boolean = true;
        // Client Name
        if (!(this.validationService.validateNonEmpty(client.ClientName))) {
            validation = false;
            this.clientNameWarnings();
        }
        // Client Id
        if (!(this.validationService.validateNonEmpty(client.ClientId)) ||
            !(this.validationService.validateWhiteSpace(client.ClientId))) {
            validation = false;
            this.clientIdWarnings();
        }
        // Client Secret
        if (!(this.validationService.validateNonEmpty(client.clientSecret)) ||
            !(this.validationService.validateWhiteSpace(client.clientSecret)) ||
            (client.clientSecret != client.repeatClientSecret)) {
            validation = false;
            this.clientSecretWarnings();
            this.clientRepeatSecretWarnings();
        }
        // Algorithm Date
        if (!(this.validationService.validateDateMoment(client.expiryDate)) ||
            ($('#m_datetimepicker_2').val() == '')) {
            validation = false;
            this.clientExpiryDateWarnings();
        }
        return validation;
    }

    public submitEdit(): void {
        this.clearWarnings();
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

        let account_type_data = $('#m_select2_1').select2('data')[0];
        let account_type = '';
        if (account_type_data.id == 0) {
            account_type = this.constantsService.SUPER_ACCOUNT;
        } else if (account_type_data.id == 1) {
            account_type = this.constantsService.CLIENT_ACCOUNT;
        }

        let account_status_data = $('#m_select2_2').select2('data')[0];
        let account_status = '';
        if (account_status_data.id == 0) {
            account_status = this.constantsService.ACTIVE_STATUS;
        } else if (account_status_data.id == 1) {
            account_status = this.constantsService.SUSPENDED_STATUS;
        }

        let date_string = $("#m_datetimepicker_2").data("datetimepicker").getDate();
        let date = moment(date_string).toISOString();
        // date.format("YYYY-MM-DD HH:mm:ss");

        let client = {
            'id': this.currentSelectedClient.id,
            'ClientName': this.currentSelectedClient.clientName,
            'ClientId': this.currentSelectedClient.clientId,
            'accountType': account_type,
            'status': account_status,
            'maxUsers': $('#m_touchspin_1').val(),
            'loggingIntervalDays': $('#m_touchspin_2').val(),
            'expiryDate': date,
            'clientSecret': this.currentSelectedClient.clientSecret,
            'repeatClientSecret': this.currentSelectedClient.repeatClientSecret
        }

        btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

        if (this.validate(client)) {
            // post the client
            this.clientService.putClient(client).subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.cancelEdit();
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                    this.getAllClients();
                    $('#m_modal_2').modal('hide');
                },
                (err) => {
                    Swal(
                        'Oops...',
                        err._body,
                        'error'
                    );
                    // if reached here, it means the response is error
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                });
        } else {
            btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
        }
    }

    public cancelEdit(): void {
        this.currentSelectedClient = {};
        $('#m_datetimepicker_2').val("").datepicker("update");
        this.clearWarnings();
    }

    InitTable(): void {
        let self = this;
        // begin first table
        this.datatable = $('#m_table_1').DataTable({
            data: this.allClients,
            responsive: true,
            paging: true,
            //== DOM Layout settings
            dom: "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                'lengthMenu': 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            headerCallback: function(thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                        <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                            <input type="checkbox" value="" class="m-group-checkable">
                            <span></span>
                        </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    data: "id",
                    width: '30px',
                    className: 'dt-right',
                    orderable: false,
                    render: function(data, type, full, meta) {
                        return `
                            <label id="` + full.id + `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                                <input type="checkbox" value="" class="m-checkable">
                                <span></span>
                            </label>`;
                    },
                },
                {
                    targets: 1,
                    data: "clientName",
                    title: "Client Name",
                    className: "text-center"
                },
                {
                    targets: 2,
                    data: "clientId",
                    title: "Client ID",
                    className: "text-center"
                },
                {
                    targets: 3,
                    data: "status",
                    title: "Account Status",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'Active', 'class': 'm-badge--primary' },
                            1: { 'title': 'Suspended', 'class': ' m-badge--metal' },
                            2: { 'title': 'Unknown', 'class': ' m-badge--warning' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;;
                        if (data == self.constantsService.ACTIVE_STATUS) {
                            location = 0;
                        } else if (data == self.constantsService.SUSPENDED_STATUS) {
                            location = 1;
                        } else {
                            location = 2;
                        }
                        return '<span class="m-badge ' + status[location].class + ' m-badge--wide">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 4,
                    data: "accountType",
                    title: "Account Type",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var status = {
                            0: { 'title': 'Super', 'state': 'success' },
                            1: { 'title': 'Client', 'state': 'primary' },
                            2: { 'title': 'Unknown', 'state': 'warning' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        let location: number = 0;;
                        if (data == self.constantsService.SUPER_ACCOUNT) {
                            location = 0;
                        } else if (data == self.constantsService.CLIENT_ACCOUNT) {
                            location = 1;
                        } else {
                            location = 2;
                        }
                        return '<span class="m-badge m-badge--' + status[location].state + ' m-badge--dot"></span>&nbsp;' +
                            '<span class="m--font-bold m--font-' + status[location].state + '">' + status[location].title + '</span>';
                    },
                },
                {
                    targets: 5,
                    data: "maxUsers",
                    title: "Max Users",
                    className: "text-center"
                },
                {
                    targets: 6,
                    data: "loggingIntervalDays",
                    title: "Logging Interval",
                    className: "text-center"
                },
                {
                    targets: 7,
                    data: "creationDate",
                    title: "Creation Date",
                    render: function(data, type, full, meta) {
                        let dateISOString = data
                        let date = new Date(dateISOString);
                        date = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
                        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                        return '<span>' + date.toLocaleString([], options) + '</span>';
                    },
                },
                {
                    targets: 8,
                    data: "expiryDate",
                    title: "Expiry date",
                    render: function(data, type, full, meta) {
                        let dateISOString = data
                        let date = new Date(dateISOString);
                        date = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
                        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                        return '<span>' + date.toLocaleString([], options) + '</span>';
                    },
                },
                {
                    targets: 9,
                    width: '100px',
                    title: 'Actions',
                    orderable: false,
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        return `
                            <span class="dropdown">
                                <a href="#" class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="dropdown" aria-expanded="true">
                                  <i class="la la-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a id="` + full.id + `" class="dropdown-item" href="javascript:void(0);"><i class="la la-edit"></i> Edit Details</a>
                                    <a id="` + full.id + `" class="dropdown-item" href="javascript:void(0);"><i class="la la-eye"></i> Show Details</a>
                                </div>
                            </span>
                            <a href="javascript:void(0);" id="`+ full.id + `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
                              <i class="la la-trash"></i>
                            </a>`;
                    },
                }
            ],
        });

        this.datatable.on('change', '.m-group-checkable', function() {
            var set = $(this).closest('table').find('td:first-child .m-checkable');
            var checked = $(this).is(':checked');

            $(set).each(function() {
                if (checked) {
                    $(this).prop('checked', true);
                    $(this).closest('tr').addClass('active');
                }
                else {
                    $(this).prop('checked', false);
                    $(this).closest('tr').removeClass('active');
                }
            });

            if (checked) {
                for (let i = 0; i < self.allClients.length; i++) {
                    self.selectedClients[i] = self.allClients[i].id;
                }
            } else {
                self.selectedClients = [];
            }
        });

        this.datatable.on('change', 'tbody tr .m-checkbox', function() {
            let id = $(this).attr('id');

            let isChecked = $(this).parents('tr').find('td:first-child .m-checkable').is(':checked');

            if (isChecked) {
                //return index of element, if not found it will return -1
                let found = $.inArray(id, self.selectedClients);
                if (found < 0) {
                    // Element was not found, add it.
                    self.selectedClients.push(id);
                }
            } else {
                // return index of element, if not found it will return -1
                let found = $.inArray(id, self.selectedClients);
                if (found >= 0) {
                    // Element was found, remove it.
                    self.selectedClients.splice(found, 1);
                }
            }
        });

        $('#m_table_1').on('click', 'a.m-portlet__nav-link', function() {
            let id = $(this).attr('id');
            self.DeleteClient(id);
        });

        $('#m_table_1').on('click', 'div.dropdown-menu > a.dropdown-item', function() {
            if ($(this).children('.la-edit').length > 0) {
                // edit is clicked
                let id = $(this).attr('id');
                self.clientService.getClient(id).subscribe(
                    (response) => {
                        if (response.value != null) {
                            self.currentSelectedClient = response.value;
                            self.currentSelectedClient.clientSecret = '';
                            self.currentSelectedClient.repeatClientSecret = '';

                            let dateTime = moment(self.currentSelectedClient.expiryDate).format("YYYY/MM/DD");
                            $('#m_datetimepicker_2').val(dateTime);

                            if (self.currentSelectedClient.accountType == self.constantsService.SUPER_ACCOUNT) {
                                $('#m_select2_1').val(0).trigger('change'); // this will set selection to first item
                            } else if (self.currentSelectedClient.accountType == self.constantsService.CLIENT_ACCOUNT) {
                                $('#m_select2_1').val(1).trigger('change'); // this will set selection to second item
                            }

                            if (self.currentSelectedClient.status == self.constantsService.ACTIVE_STATUS) {
                                $('#m_select2_2').val(0).trigger('change'); // this will set selection to first item
                            } else if (self.currentSelectedClient.status == self.constantsService.SUSPENDED_STATUS) {
                                $('#m_select2_2').val(0).trigger('change'); // this will set selection to first item
                            }
                            $('#m_modal_2').modal('show');
                        }
                    }, (err) => {
                        self.getAllClients();
                        Swal(
                            'Cancelled',
                            'Error occured!',
                            'error'
                        )
                    }
                );
            } else if ($(this).children('.la-eye').length > 0) {
                // show is clicked
                let id = $(this).attr('id');
                self.clientService.getClient(id).subscribe(
                    (response) => {
                        if (response.value != null) {
                            self.currentSelectedClient = response.value;
                            // self.currentSelectedClient.expiryDate = self.currentSelectedClient.expiryDate.toLocaleString();
                            let startTimeISOString = self.currentSelectedClient.expiryDate
                            let startTime = new Date(startTimeISOString);
                            startTime = new Date(startTime.getTime() + (startTime.getTimezoneOffset() * 60000));
                            self.currentSelectedClient.expiryDate = startTime;
                            // self.currentSelectedClient.creationDate = self.currentSelectedClient.creationDate.toLocaleString();
                            startTimeISOString = self.currentSelectedClient.creationDate
                            startTime = new Date(startTimeISOString);
                            startTime = new Date(startTime.getTime() + (startTime.getTimezoneOffset() * 60000));
                            self.currentSelectedClient.creationDate = startTime;
                            $('#m_modal_1').modal('show');
                        }
                    }, (err) => {
                        self.getAllClients();
                        Swal(
                            'Cancelled',
                            'Error occured!',
                            'error'
                        )
                    }
                );
            }
        });
    }

    public DeleteClient(id: string): void {
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover client again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                self.clientService.deleteClient(id).subscribe(
                    (response) => {
                        self.getAllClients();
                        // Swal(
                        //     'Deleted!',
                        //     'Client has been deleted.',
                        //     'success'
                        // );
                    }, (err) => {
                        self.getAllClients();
                        Swal(
                            'Cancelled',
                            err._body,
                            'error'
                        )
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        })
    }

    public DeleteClients(): void {
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover those clients again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                self.clientService.deleteClients(self.selectedClients).subscribe(
                    (response) => {
                        self.getAllClients();
                        // Swal(
                        //     'Deleted!',
                        //     'Client has been deleted.',
                        //     'success'
                        // );
                    }, (err) => {
                        self.getAllClients();
                        Swal(
                            'Cancelled',
                            'Error occured and clients were not deleted',
                            'error'
                        )
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        })
    }
}
