import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ConstantsService } from '../../services/utils/constants.service';
import { ApartmentService } from '../../services/apartment/apartment.service';
import { ValidationService } from '../../services/utils/validation.service';
import Swal from 'sweetalert2';
import { ClientService } from '../../services/client/client.service';
import { UserService } from '../../services/user/user.service';

declare var $: any;
declare var mApp: any;
declare var mUtil: any;

@Component({
    selector: 'app-apartment-manage',
    templateUrl: './apartment-manage.component.html',
    styleUrls: ['./apartment-manage.component.css']
})
export class ApartmentManageComponent implements OnInit, AfterViewInit {
    public name: string = '';
    public info: string = '';
    private readonly SPAN_NORMAL_NAME: string = 'Please enter name';
    private readonly SPAN_DANGER_NAME: string = 'Name is not correct!';
    public span_name: string;
    public isDeveloper: boolean = false;
    public isSuper: boolean = false;
    public isAdmin: boolean = false;
    public allApartments: any[] = [];
    public allClients: any[] = [];
    private datatable: any;
    public selectedApartments: any[] = [];
    public currentSelectedApartment: any = {};
    public image: string = '';
    private file: File = null;
    private readonly IMAGE_PLACEHOLDER: string = 'Choose file';

    @ViewChildren('ngForRendred') ngForList: QueryList<any>;

    constructor(private constantsService: ConstantsService, private apartmentService: ApartmentService,
        private clientService: ClientService, private userService: UserService,
        private validationService: ValidationService) { }

    ngOnInit() {
        this.image = this.IMAGE_PLACEHOLDER;

        mApp.block('#m_table_1', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...'
        });

        mApp.block('#m_modal_2 .modal-content', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...'
        });

        this.clearWarnings();
        this.userService.getMe().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let me = response.value;
                if (me.role == this.constantsService.ROLE_DEVELOPER) {
                    this.isDeveloper = true;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_SUPER_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = false;
                    this.isAdmin = true;
                }

                if (this.isSuper) {
                    this.getAllClients();
                } else {
                    mApp.unblock('#m_modal_2 .modal-content');
                }
            },
            (err) => {
                // if reached here, it means the response is error
            });
        this.getAllApartments();
    }

    ngAfterViewInit() {
        this.ngForList.changes.subscribe(t => {
            this.ngForRendredCallback();
        });
    }

    ngForRendredCallback() {
        $('#m_select2_1').select2({
            placeholder: "Select client",
            allowClear: true,
            width: '100%'
        });
        $('#m_select2_1').val(null).trigger('change'); // this will set selection to first item
    }

    private clearWarnings(): void {
        this.span_name = this.SPAN_NORMAL_NAME;

        $('#namespan').removeClass();
        $('#namespan').addClass('m-form__help');
        $('#namelabel').removeClass();
    }

    public fileChange(event) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.file = fileList[0];
            this.image = fileList[0].name;
        }
    }

    private nameWarnings(): void {
        this.span_name = this.SPAN_DANGER_NAME;
        $('#namespan').removeClass();
        $('#namespan').addClass('m-form__help m--font-danger');
        $('#namelabel').removeClass();
        $('#namelabel').addClass('m--font-danger');
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                mApp.unblock('#m_modal_2 .modal-content');
            },
            (err) => {
                // if reached here, it means the response is error
                this.allClients = [];
                mApp.unblock('#m_modal_2 .modal-content');
            });
    }

    getAllApartments(): void {
        this.apartmentService.getAllApartments().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allApartments = response.value;
                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            },
            (err) => {
                // if reached here, it means the response is error
                this.allApartments = [];
                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            });
    }

    private validate(apartment: any): boolean {
        let validation: boolean = true;
        // Name
        if (!(this.validationService.validateNonEmpty(apartment.name))) {
            validation = false;
            this.nameWarnings();
        }
        return validation;
    }

    public cancelEdit(): void {
        this.currentSelectedApartment = {};
        $('#m_select2_1').val(null).trigger('change'); // this will set selection to first item
        this.file = null;
        this.image = this.IMAGE_PLACEHOLDER;
        this.clearWarnings();
    }

    public submitEdit(): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            )

            return;
        }
        this.clearWarnings();
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

        let client = (this.isSuper) ? $('#m_select2_1').select2('data')[0].text : null;

        let apartment = {
            'id': this.currentSelectedApartment.id,
            'name': this.currentSelectedApartment.name,
            'info': this.currentSelectedApartment.info,
            'client': client
        }

        btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

        if (this.validate(apartment)) {
            // put the apartment
            this.apartmentService.putApartment(apartment, this.file).subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.cancelEdit();
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                    this.getAllApartments();
                    $('#m_modal_2').modal('hide');
                },
                (err) => {
                    Swal(
                        'Oops...',
                        err._body,
                        'error'
                    );
                    // if reached here, it means the response is error
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                });
        } else {
            btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
        }
    }

    InitTable(): void {
        let self = this;
        // begin first table
        this.datatable = $('#m_table_1').DataTable({
            data: this.allApartments,
            responsive: true,
            paging: true,
            //== DOM Layout settings
            dom: "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                'lengthMenu': 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            headerCallback: function(thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                        <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                            <input type="checkbox" value="" class="m-group-checkable">
                            <span></span>
                        </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    data: "id",
                    width: '30px',
                    className: 'dt-right',
                    orderable: false,
                    render: function(data, type, full, meta) {
                        return `
                            <label id="` + full.id + `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                                <input type="checkbox" value="" class="m-checkable">
                                <span></span>
                            </label>`;
                    },
                },
                {
                    targets: 1,
                    data: "image",
                    title: "Image",
                    visible: false
                },
                {
                    targets: 2,
                    data: "name",
                    title: "Name",
                    visible: false
                },
                {
                    targets: 3,
                    title: 'Apartment',
                    orderable: false,
                    className: "dt-left",
                    render: function(data, type, full, meta) {
                        var output;
                        if (full.image == null) {
                            // No image
                            var stateNo = mUtil.getRandomInt(0, 7);
                            var states = [
                                'success',
                                'brand',
                                'danger',
                                'accent',
                                'warning',
                                'metal',
                                'primary',
                                'info'];
                            var state = states[stateNo];

                            output = `
                                            <div class="m-card-user m-card-user--sm">
                                                <div class="m-card-user__pic">
                                                    <div class="m-card-user__no-photo m--bg-fill-` + state + `"><span>` + full.name.substring(0, 1) + `</span></div>
                                                </div>
                                                <div class="m-card-user__details">
                                                    <span class="m-card-user__name">` + full.name + `</span>
                                                    <span href="" class="m-card-user__name" style="padding-left: 10px;">` + full.client + `</span>
                                                </div>
                                            </div>`;
                        } else {
                            if (mUtil.isMobileDevice()) {
                                output = `
                                              <div class="m-card-user m-card-user--sm">
                                                  <div class="m-card-user__details">
                                                      <span class="m-card-user__name" style="padding-left: 10px;">` + full.name + `</span>
                                                      <span href="" class="m-card-user__name" style="padding-left: 10px;">` + full.client + `</span>
                                                  </div>
                                              </div>`;
                            } else {
                                output = `
                                              <div class="m-card-user m-card-user--sm">
                                                  <div class="thumbnail">
                                                      <a href="` + self.constantsService.STATIC_URL + '/apartment/' + full.image + `" target="_blank">
                                                          <img src="` + self.constantsService.STATIC_URL + '/apartment/' + full.image + `" class="portrait" alt="photo">
                                                      </a>
                                                  </div>
                                                  <div class="m-card-user__details">
                                                      <span class="m-card-user__name" style="padding-left: 10px;">` + full.name + `</span>
                                                      <span href="" class="m-card-user__name" style="padding-left: 10px;">` + full.client + `</span>
                                                  </div>
                                              </div>`;
                            }
                        }

                        return output;
                    }
                },
                {
                    targets: 4,
                    data: "info",
                    title: "Info",
                    className: "text-center"
                },
                {
                    targets: 5,
                    data: "client",
                    title: "Client",
                    className: "text-center"
                },
                {
                    targets: 6,
                    width: '100px',
                    title: 'Actions',
                    orderable: false,
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        return `
                            <span class="dropdown">
                                <a href="#" class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="dropdown" aria-expanded="true">
                                  <i class="la la-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a id="` + full.id + `" class="dropdown-item" href="javascript:void(0);"><i class="la la-edit"></i> Edit Details</a>
                                    <a id="` + full.id + `" class="dropdown-item" href="javascript:void(0);"><i class="la la-eye"></i> Show Details</a>
                                </div>
                            </span>
                            <a href="javascript:void(0);" id="`+ full.id + `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
                              <i class="la la-trash"></i>
                            </a>`;
                    },
                }
            ],
        });

        this.datatable.on('change', '.m-group-checkable', function() {
            var set = $(this).closest('table').find('td:first-child .m-checkable');
            var checked = $(this).is(':checked');

            $(set).each(function() {
                if (checked) {
                    $(this).prop('checked', true);
                    $(this).closest('tr').addClass('active');
                }
                else {
                    $(this).prop('checked', false);
                    $(this).closest('tr').removeClass('active');
                }
            });

            if (checked) {
                for (let i = 0; i < self.allApartments.length; i++) {
                    self.selectedApartments[i] = self.allApartments[i].id;
                }
            } else {
                self.selectedApartments = [];
            }
        });

        this.datatable.on('change', 'tbody tr .m-checkbox', function() {
            let id = $(this).attr('id');

            let isChecked = $(this).parents('tr').find('td:first-child .m-checkable').is(':checked');

            if (isChecked) {
                //return index of element, if not found it will return -1
                let found = $.inArray(id, self.selectedApartments);
                if (found < 0) {
                    // Element was not found, add it.
                    self.selectedApartments.push(id);
                }
            } else {
                // return index of element, if not found it will return -1
                let found = $.inArray(id, self.selectedApartments);
                if (found >= 0) {
                    // Element was found, remove it.
                    self.selectedApartments.splice(found, 1);
                }
            }
        });

        $('#m_table_1').on('click', 'a.m-portlet__nav-link', function() {
            let id = $(this).attr('id');
            self.DeleteApartment(id);
        });

        $('#m_table_1').on('click', 'div.dropdown-menu > a.dropdown-item', function() {
            if ($(this).children('.la-edit').length > 0) {
                // edit is clicked
                let id = $(this).attr('id');
                self.apartmentService.getApartment(id).subscribe(
                    (response) => {
                        if (response.value != null) {
                            self.currentSelectedApartment = response.value;
                            $("#m_select2_1").val($("#m_select2_1 option:contains('" +
                                self.currentSelectedApartment.client + "')").val()).trigger('change');
                            $('#m_modal_2').modal('show');
                        }
                    }, (err) => {
                        self.getAllApartments();
                        Swal(
                            'Cancelled',
                            'Error occured!',
                            'error'
                        )
                    }
                );
            } else if ($(this).children('.la-eye').length > 0) {
                // show is clicked
                let id = $(this).attr('id');
                self.apartmentService.getApartment(id).subscribe(
                    (response) => {
                        if (response.value != null) {
                            self.currentSelectedApartment = response.value;
                            if (self.currentSelectedApartment.image == null) {
                                self.currentSelectedApartment.imageExist = false;
                                var stateNo = mUtil.getRandomInt(0, 7);
                                var states = [
                                    'success',
                                    'brand',
                                    'danger',
                                    'accent',
                                    'warning',
                                    'metal',
                                    'primary',
                                    'info'];
                                var state = states[stateNo];
                                $('#nophoto').removeClass();
                                $('#nophoto').addClass('m-card-user__no-photo m--bg-fill-' + state);
                                self.currentSelectedApartment.subname = self.currentSelectedApartment.name.substring(0, 1);
                            } else {
                                self.currentSelectedApartment.imageExist = true;
                            }
                            $('#m_modal_1').modal('show');
                        }
                    }, (err) => {
                        self.getAllApartments();
                        Swal(
                            'Cancelled',
                            'Error occured!',
                            'error'
                        )
                    }
                );
            }
        });
    }

    public DeleteApartment(id: string): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            )

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover apartment again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                self.apartmentService.deleteApartment(id).subscribe(
                    (response) => {
                        self.getAllApartments();
                        // Swal(
                        //     'Deleted!',
                        //     'Apartment has been deleted.',
                        //     'success'
                        // );
                    }, (err) => {
                        self.getAllApartments();
                        Swal(
                            'Cancelled',
                            err._body,
                            'error'
                        )
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        })
    }

    public DeleteApartments(): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            )

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover those apartments again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                self.apartmentService.deleteApartments(self.selectedApartments).subscribe(
                    (response) => {
                        self.getAllApartments();
                        // Swal(
                        //     'Deleted!',
                        //     'Client has been deleted.',
                        //     'success'
                        // );
                    }, (err) => {
                        self.getAllApartments();
                        Swal(
                            'Cancelled',
                            'Error occured and apartments were not deleted',
                            'error'
                        )
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        })
    }
}
