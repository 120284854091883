import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../services/token/token.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user/user.service';
import { ConstantsService } from '../../services/utils/constants.service';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {
    public username: string;
    public name: string;
    public email: string;
    public avatar: string;

    constructor(private tokenService: TokenService, private router: Router,
        private userService: UserService, private constantsService: ConstantsService) { }

    ngOnInit() {
        this.userService.getMe().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let me = response.value;
                this.username = me.username;
                this.name = me.name;
                this.email = me.email;
                this.avatar = me.avatar;
                if (this.avatar == null) {
                    this.avatar = '../../../assets/media/no_user.png';
                } else {
                    this.avatar = this.constantsService.STATIC_URL + '/avatar/' + this.avatar;
                }
            },
            (err) => {
                // if reached here, it means the response is error
            });
    }

    public logout(): void {
        this.tokenService.clearCredentials();
        this.tokenService.clearToken();
        this.tokenService.clearClient();
        this.router.navigateByUrl('/login)');
    }

}
