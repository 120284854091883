import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import { ConstantsService } from '../../services/utils/constants.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    public isDeveloper: boolean = false;
    public isSuper: boolean = false;
    public isAdmin: boolean = false;

    constructor(private userService: UserService, private constantsService: ConstantsService) { }

    ngOnInit() {
        this.userService.getMe().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let me = response.value;
                if (me.role == this.constantsService.ROLE_DEVELOPER) {
                    this.isDeveloper = true;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_SUPER_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = false;
                    this.isAdmin = true;
                }
            },
            (err) => {
                // if reached here, it means the response is error
            });
    }

}
