import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../../services/utils/utils.service';
import { TokenService } from '../../services/token/token.service';

declare var $: any;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
    public rememberMe: boolean = false;
    public email: string = '';
    public password: string = '';

    constructor(private router: Router, private utilsService: UtilsService,
        private tokenService: TokenService) { }

    ngOnInit() {
        // Update the background
        $(document.body).removeClass();
        $(document.body).addClass('m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default');
        //$('#body').css("background-image", "url(src/assets/metronic/app/media/img/bg/bg-2.jpg)");

        this.handleFormSwitch();

        /* Check if already a cookie of the user is saved so no need to relog,
                just log directly using the saved credentials */

        let credentials = this.tokenService.getCredentials();
        if (credentials) {
            this.tokenService.authenticate(credentials.username, credentials.password).subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.router.navigateByUrl('/home)');
                },
                (err) => {
                    // if reached here, it means the response is error
                    this.tokenService.clearCredentials();
                    this.tokenService.clearToken();
                    this.tokenService.clearClient();
                }
            );
        } else {
            this.tokenService.clearCredentials();
            this.tokenService.clearToken();
            this.tokenService.clearClient();
        }
    }

    private showErrorMsg(formId: string, type: string, msg: string) {
        let form = $(formId).closest('form');
        let alert = $('<div class="m-alert m-alert--outline alert alert-' + type + ' alert-dismissible" role="alert">\
			                 <button type="button" class="close" data-dismiss="alert" aria-label="Close"></button>\
			                 <span></span>\
		                   </div>');

        form.find('.alert').remove();
        alert.prependTo(form);
        this.utilsService.animate(alert, 'fadeIn animated');
        alert.find('span').html(msg);
    }

    private displaySignInForm() {
        $('#m_login').removeClass('m-login--forget-password');

        $('#m_login').addClass('m-login--signin');
        this.utilsService.animate($('#m_login').find('.m-login__signin'), 'flipInX animated');
    }

    private displayForgetPasswordForm() {
        $('#m_login').removeClass('m-login--signin');

        $('#m_login').addClass('m-login--forget-password');

        this.utilsService.animate($('#m_login').find('.m-login__forget-password'), 'flipInX animated');
    }

    public handleFormSwitch() {
        let self = this;
        $('#m_login_forget_password').click(function(e) {
            e.preventDefault();
            self.displayForgetPasswordForm();
        });

        $('#m_login_forget_password_cancel').click(function(e) {
            e.preventDefault();
            self.displaySignInForm();
        });
    }

    public handleSignInFormSubmit() {
        let btn = $('#m_login_signin_submit');
        let form = $('#m_login_signin_submit').closest('form');
        // This validation for email or username
        form.validate({
            rules: {
                email: {
                    required: true
                },
                password: {
                    required: true
                }
            }
        });

        if (!form.valid()) {
            return;
        }

        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

        // Check the login credentials
        this.tokenService.authenticate(this.rememberMe, this.email, this.password).subscribe(
            (response) => {
                // if reached here, it means the response is success
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                this.email = '';
                this.password = '';
                this.rememberMe = false;
                $("#body").css('background-image', 'none');
                $('#body').removeClass();
                this.tokenService.setCredentials(this.rememberMe, this.email, this.password);
                this.router.navigateByUrl('/home)');
            },
            (err) => {
                // if reached here, it means the response is error
                btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                this.showErrorMsg('#m_login_signin_submit', 'danger', 'Incorrect username or password. Please try again.');
                this.tokenService.clearCredentials();
                this.tokenService.clearToken();
            }
        );
    }

    public handleForgetPasswordFormSubmit() {
        let self = this;
        let btn = $('#m_login_forget_password_submit');
        let form = $('#m_login_forget_password_submit').closest('form');

        form.validate({
            rules: {
                email: {
                    required: true,
                    email: true
                }
            }
        });

        if (!form.valid()) {
            return;
        }

        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

        setTimeout(function() {
            btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false); // remove
            form.clearForm(); // clear form
            form.validate().resetForm(); // reset validation states

            // display signup form
            self.displaySignInForm();
            let signInForm = $('#m_login').find('.m-login__signin form');
            signInForm.clearForm();
            signInForm.validate().resetForm();

            self.showErrorMsg('.m-login__signin form', 'success', 'Cool! Password recovery instruction has been sent to your email.');
        }, 2000);
    }

    ngOnDestroy() {
        $(document.body).removeClass();
        // this.renderer.removeClass(document.body, 'm--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default');
    }

}
