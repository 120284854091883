import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ConstantsService } from '../../services/utils/constants.service';
import { ApartmentService } from '../../services/apartment/apartment.service';
import { ValidationService } from '../../services/utils/validation.service';
import Swal from 'sweetalert2';
import { ClientService } from '../../services/client/client.service';
import { UserService } from '../../services/user/user.service';
import { RoomService } from '../../services/room/room.service';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-room-add',
    templateUrl: './room-add.component.html',
    styleUrls: ['./room-add.component.css']
})
export class RoomAddComponent implements OnInit {
    public name: string = '';
    public info: string = '';
    private readonly SPAN_NORMAL_NAME: string = 'Please enter name';
    private readonly SPAN_DANGER_NAME: string = 'Name is not correct!';
    public span_name: string;
    public isDeveloper: boolean = false;
    public isSuper: boolean = false;
    public isAdmin: boolean = false;
    public allClients: any[] = [];
    public allApartments: any[] = [];
    private isApartmentsLoaded: boolean = false;
    private isClientsLoaded: boolean = false;

    @ViewChildren('ngForRendred') ngForList: QueryList<any>;

    constructor(private constantsService: ConstantsService, private apartmentService: ApartmentService,
        private clientService: ClientService, private userService: UserService,
        private validationService: ValidationService, private roomService: RoomService) { }

    ngOnInit() {
        mApp.blockPage();
        this.clearWarnings();

        this.userService.getMe().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let me = response.value;
                if (me.role == this.constantsService.ROLE_DEVELOPER) {
                    this.isDeveloper = true;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_SUPER_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = false;
                    this.isAdmin = true;
                }

                if (this.isSuper) {
                    this.getAllClients();
                } else {
                    mApp.unblockPage();
                }
            },
            (err) => {
                // if reached here, it means the response is error
            });

        this.getAllApartments();
    }

    ngAfterViewInit() {
        this.ngForList.changes.subscribe(t => {
            this.ngForRendredCallback();
        });
    }

    ngForRendredCallback() {
        $('#m_select2_1').select2({
            placeholder: "Select apartment",
            allowClear: false,
            width: '100%'
        });
        $('#m_select2_1').val(0).trigger('change'); // this will set selection to first item

        $('#m_select2_2').select2({
            placeholder: "Select client",
            allowClear: true,
            width: '100%'
        });
        $('#m_select2_2').val(null).trigger('change'); // this will set selection to first item
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                this.isClientsLoaded = true;
                if (this.isClientsLoaded && this.isApartmentsLoaded) {
                    mApp.unblockPage();
                }
            },
            (err) => {
                // if reached here, it means the response is error
                this.isClientsLoaded = true;
                if (this.isClientsLoaded && this.isApartmentsLoaded) {
                    mApp.unblockPage();
                }
            });
    }

    getAllApartments(): void {
        this.apartmentService.getAllApartments().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allApartments = response.value;
                this.isApartmentsLoaded = true;
                if (this.isClientsLoaded && this.isApartmentsLoaded) {
                    mApp.unblockPage();
                }
            },
            (err) => {
                // if reached here, it means the response is error
                this.isApartmentsLoaded = true;
                if (this.isClientsLoaded && this.isApartmentsLoaded) {
                    mApp.unblockPage();
                }
            });
    }

    private validate(room: any): boolean {
        let validation: boolean = true;
        // Name
        if (!(this.validationService.validateNonEmpty(room.name))) {
            validation = false;
            this.nameWarnings();
        }
        return validation;
    }

    public submit(): void {
        this.clearWarnings();
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

        let apartment_name = $('#m_select2_1').select2('data')[0];
        if (typeof apartment_name != 'undefined') {
            apartment_name = apartment_name.text
        } else {
            apartment_name = null;
        }

        let client = (this.isSuper)? $('#m_select2_2').select2('data')[0].text : null;

        let room = {
            name: this.name,
            info: this.info,
            apartmentName: apartment_name,
            client: client
        }

        if (this.validate(room)) {
            // post the apartment
            this.roomService.postRoom(room).subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.cancel();
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                },
                (err) => {
                    Swal(
                        'Oops...',
                        err._body,
                        'error'
                    );
                    // if reached here, it means the response is error
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                });
        } else {
            btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
        }
    }

    public cancel(): void {
        this.name = '';
        this.info = '';
        $('#m_select2_1').val(0).trigger('change'); // this will set selection to first item
        $('#m_select2_2').val(null).trigger('change'); // this will set selection to no item
        this.clearWarnings();
    }

    private clearWarnings(): void {
        this.span_name = this.SPAN_NORMAL_NAME;

        $('#namespan').removeClass();
        $('#namespan').addClass('m-form__help');
        $('#namelabel').removeClass();
    }

    private nameWarnings(): void {
        this.span_name = this.SPAN_DANGER_NAME;
        $('#namespan').removeClass();
        $('#namespan').addClass('m-form__help m--font-danger');
        $('#namelabel').removeClass();
        $('#namelabel').addClass('m--font-danger');
    }

}
