import { Component, OnInit, OnDestroy } from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit, OnDestroy {

    constructor() { }

    ngOnInit() {
        // Update the background
        $('document.body').removeClass();
        $('document.body').addClass('m--skin- m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default');
    }

    ngOnDestroy() {
        $(document.body).removeClass();
    }

}
