import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { mergeMap, map, delay, catchError } from 'rxjs/operators';
import { ConstantsService } from '../utils/constants.service';
import { TokenService } from '../token/token.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private http: Http, private tokenService: TokenService,
        private constnatsService: ConstantsService) { }

    postNotification(notification: any): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                formData.append('timestamp', notification.timestamp);
                formData.append('channel', notification.channel);
                formData.append('title', notification.title);
                formData.append('description', notification.description);
                formData.append('tags', notification.tags);
                formData.append('level', notification.level);
                formData.append('client', notification.client);
                formData.append('device_mac_address', notification.device_mac_address);
                formData.append('gateway_mac_address', notification.gateway_mac_address);
                formData.append('pushEmail', notification.pushEmail);
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.post(self.constnatsService.API_CORE_URL + '/api/notifications/', formData, options)
                    .pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getAllNotificationss(): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/notifications/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getNotification(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/notifications/' + id,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteNotification(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/notifications/' + id,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteNotifications(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData
                });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/notifications/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }
}
