import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ConstantsService } from '../../services/utils/constants.service';
import { UserService } from '../../services/user/user.service';
import { ValidationService } from '../../services/utils/validation.service';
import Swal from 'sweetalert2';
import { ClientService } from '../../services/client/client.service';
import { ApartmentService } from '../../services/apartment/apartment.service';

declare var $: any;
declare var mApp: any;

@Component({
    selector: 'app-user-add',
    templateUrl: './user-add.component.html',
    styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit, AfterViewInit {
    public name: string = '';
    public username: string = '';
    public password: string = '';
    public repeat_password: string = '';
    public email: string = '';
    public phone: string = '';
    public role: string = '';
    public avatar: string = '';
    private file: File = null;
    public profile: string = '';
    private readonly AVATAR_PLACEHOLDER: string = 'Choose file';

    private readonly SPAN_NORMAL_NAME: string = 'Please enter full name';
    private readonly SPAN_DANGER_NAME: string = 'Name is not correct!';
    private readonly SPAN_NORMAL_USERNAME: string = 'Please enter username';
    private readonly SPAN_DANGER_USERNAME: string = 'Username is not correct!';
    private readonly SPAN_NORMAL_PASSWORD: string = 'Please enter password';
    private readonly SPAN_DANGER_PASSWORD: string = 'Password doesnot match!';
    private readonly SPAN_NORMAL_REPEAT_PASSWORD: string = 'Please confirm password';
    private readonly SPAN_DANGER_REPEAT_PASSWORD: string = 'Password doesnot match!';
    private readonly SPAN_NORMAL_EMAIL: string = 'Please enter email';
    private readonly SPAN_DANGER_EMAIL: string = 'Email is not in correct format!';
    private readonly SPAN_NORMAL_PHONE: string = 'Please enter phone';
    private readonly SPAN_DANGER_PHONE: string = 'Phone is not in correct format!';

    public span_name: string;
    public span_username: string;
    public span_password: string;
    public span_repeat_password: string;
    public span_email: string;
    public span_phone: string;

    public isDeveloper: boolean = false;
    public isSuper: boolean = false;
    public isAdmin: boolean = false;
    public allClients: any[] = [];
    public allApartments: string[] = [];
    private clientsLoaded: boolean = false;

    @ViewChildren('ngForRendred') ngForList: QueryList<any>;

    constructor(private constantsService: ConstantsService, private userService: UserService,
        private validationService: ValidationService, private clientService: ClientService,
        private apartmentService: ApartmentService) { }

    ngOnInit() {
        this.avatar = this.AVATAR_PLACEHOLDER;

        mApp.blockPage();
        this.clearWarnings();

        this.userService.getMe().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let me = response.value;
                if (me.role == this.constantsService.ROLE_DEVELOPER) {
                    this.isDeveloper = true;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_SUPER_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = false;
                    this.isAdmin = true;
                }

                if (this.isSuper) {
                    this.getAllClients();
                } else {
                    this.getAllApartments();
                }
            },
            (err) => {
                // if reached here, it means the response is error
            });
    }

    ngAfterViewInit() {
        $('#phone').intlTelInput({
            initialCountry: "auto",
            geoIpLookup: function(callback) {
                $.get('https://ipinfo.io', function() { }, "jsonp").always(function(resp) {
                    var countryCode = (resp && resp.country) ? resp.country : "";
                    callback(countryCode);
                });
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.4.0/js/utils.js" // just for formatting/placeholders etc
        });

        // fix the width problem of intl-tel-input,
        // applying this in css has no effect because textbox is converted after view init
        $('.intl-tel-input').css("width", "100%");

        // basic
        $('#m_select2_1').select2({
            placeholder: "Select role",
            allowClear: false,
            width: '100%'
        });

        // basic
        $('#m_select2_4').select2({
            placeholder: "Select profile",
            allowClear: false,
            width: '100%'
        });

        this.ngForList.changes.subscribe(t => {
            this.ngForRendredCallback();
        });
    }

    ngForRendredCallback() {
        if (!this.clientsLoaded) {
            $('#m_select2_2').select2({
                placeholder: "Select client",
                allowClear: true,
                width: '100%'
            });
            $('#m_select2_2').val(null).trigger('change'); // this will set selection to first item
        }
        let self = this;
        $('#m_select2_3').select2({
            placeholder: "Select apartments",
            allowClear: true,
            width: '100%'
        });
        $('#m_select2_3').val(null).trigger('change'); // this will set selection to first item

        $('#m_select2_2').on('select2:select', function(e) {
            // var data = e.params.data;
            self.clientsLoaded = true;
            mApp.blockPage();
            let client_name = $('#m_select2_2').select2('data')[0].text;
            self.getAllClientApartments(client_name);
        });
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                mApp.unblockPage();
            },
            (err) => {
                // if reached here, it means the response is error
                mApp.unblockPage();
            });
    }

    getAllApartments(): void {
        this.apartmentService.getAllApartments().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allApartments = response.value;
                mApp.unblockPage();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allApartments = [];
                mApp.unblockPage();
            });
    }

    getAllClientApartments(client: string): void {
        this.apartmentService.getApartmentByClient(client).subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allApartments = response.value;
                mApp.unblockPage();
            },
            (err) => {
                // if reached here, it means the response is error
                this.allApartments = [];
                mApp.unblockPage();
            });
    }

    private validate(user: any): boolean {
        let validation: boolean = true;
        // Name
        if (!(this.validationService.validateNonEmpty(user.name))) {
            validation = false;
            this.nameWarnings();
        }
        // Username
        if (!(this.validationService.validateNonEmpty(user.username)) ||
            !(this.validationService.validateWhiteSpace(user.username))) {
            validation = false;
            this.usernameWarnings();
        }
        // Password
        if (!(this.validationService.validateNonEmpty(user.password)) ||
            !(this.validationService.validateWhiteSpace(user.password)) ||
            (this.password != this.repeat_password)) {
            validation = false;
            this.passwordWarnings();
            this.repeatpasswordWarnings();
        }
        // Email
        if (!(this.validationService.validateEmail(user.email))) {
            validation = false;
            this.emailWarnings();
        }

        // Phone
        if (!$("#phone").intlTelInput("isValidNumber")) {
            validation = false;
            this.phoneWarnings();
        }
        return validation;
    }

    public submit(): void {
        this.clearWarnings();
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);
        let role_data = $('#m_select2_1').select2('data')[0];
        let role = '';
        if (role_data.id == 0) {
            role = this.constantsService.ROLE_DEVELOPER;
        } else if (role_data.id == 1) {
            role = this.constantsService.ROLE_SUPER_ADMIN;
        } else if (role_data.id == 2) {
            role = this.constantsService.ROLE_ADMIN;
        } else if (role_data.id == 3) {
            role = this.constantsService.ROLE_USER;
        }

        let profile_data = $('#m_select2_4').select2('data')[0];
        let profile = '';
        if (profile_data.id == 0) {
            profile = "current";
        } else if (profile_data.id == 1) {
            profile = "basic";
        } else if (profile_data.id == 2) {
            profile = "professional";
        } else if (profile_data.id == 3) {
            profile = "advanced";
        }

        // let detailed = $('#phone').intlTelInput("getSelectedCountryData");
        let isoCountry = $('#phone').intlTelInput("getSelectedCountryData").iso2;
        let nameCountry = $('#phone').intlTelInput("getSelectedCountryData").name;
        let fullNumber = $("#phone").intlTelInput("getNumber");
        // let dialCodeCountry = $('#phone').intlTelInput("getSelectedCountryData").dialCode;
        // let isPhoneValid = $("#phone").intlTelInput("isValidNumber");

        let client = (this.isSuper) ? $('#m_select2_2').select2('data')[0].text : null;
        let apartments = $('#m_select2_3').select2('data');
        let selected_apartments = [];
        for (let i = 0; i < apartments.length; i++) {
            selected_apartments.push(apartments[i].text);
        }

        let user = {
            name: this.name,
            username: this.username,
            password: this.password,
            repeat_password: this.repeat_password,
            email: this.email,
            phone: fullNumber,
            role: role,
            country: nameCountry,
            countryIso: isoCountry,
            client: client,
            apartments: selected_apartments,
            profile: profile
        }

        if (this.validate(user)) {
            // post the user
            this.userService.postUser(user, this.file).subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.cancel();
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                },
                (err) => {
                    Swal(
                        'Oops...',
                        err._body,
                        'error'
                    );
                    // if reached here, it means the response is error
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                });
        } else {
            btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
        }
    }

    public cancel(): void {
        this.name = '';
        this.username = '';
        this.password = '';
        this.repeat_password = '';
        this.email = '';
        this.phone = '';
        $('#m_select2_1').val(0).trigger('change'); // this will set selection to first item
        $('#m_select2_2').val(null).trigger('change'); // this will set selection to no item
        $('#m_select2_3').val(null).trigger('change'); // this will set selection to no item
        this.file = null;
        this.avatar = this.AVATAR_PLACEHOLDER;

        this.clearWarnings();
    }

    public fileChange(event) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.file = fileList[0];
            this.avatar = fileList[0].name;
        }
    }

    private clearWarnings(): void {
        this.span_name = this.SPAN_NORMAL_NAME;
        this.span_username = this.SPAN_NORMAL_USERNAME;
        this.span_password = this.SPAN_NORMAL_PASSWORD;
        this.span_repeat_password = this.SPAN_NORMAL_REPEAT_PASSWORD;
        this.span_email = this.SPAN_NORMAL_EMAIL;
        this.span_phone = this.SPAN_NORMAL_PHONE;

        $('#namespan').removeClass();
        $('#namespan').addClass('m-form__help');
        $('#namelabel').removeClass();
        $('#usernamespan').removeClass();
        $('#usernamespan').addClass('m-form__help');
        $('#usernamelabel').removeClass();
        $('#passwordspan').removeClass();
        $('#passwordspan').addClass('m-form__help');
        $('#passwordlabel').removeClass();
        $('#repeatpasswordspan').removeClass();
        $('#repeatpasswordspan').addClass('m-form__help');
        $('#repeatpasswordlabel').removeClass();
        $('#emailspan').removeClass();
        $('#emailspan').addClass('m-form__help');
        $('#emaillabel').removeClass();
        $('#phonespan').removeClass();
        $('#phonespan').addClass('m-form__help');
        $('#phonelabel').removeClass();
    }

    private nameWarnings(): void {
        this.span_name = this.SPAN_DANGER_NAME;
        $('#namespan').removeClass();
        $('#namespan').addClass('m-form__help m--font-danger');
        $('#namelabel').removeClass();
        $('#namelabel').addClass('m--font-danger');
    }

    private usernameWarnings(): void {
        this.span_username = this.SPAN_DANGER_USERNAME;
        $('#usernamespan').removeClass();
        $('#usernamespan').addClass('m-form__help m--font-danger');
        $('#usernamelabel').removeClass();
        $('#usernamelabel').addClass('m--font-danger');
    }

    private passwordWarnings(): void {
        this.span_password = this.SPAN_DANGER_PASSWORD;
        $('#passwordspan').removeClass();
        $('#passwordspan').addClass('m-form__help m--font-danger');
        $('#passwordlabel').removeClass();
        $('#passwordlabel').addClass('m--font-danger');
    }

    private repeatpasswordWarnings(): void {
        this.span_repeat_password = this.SPAN_DANGER_REPEAT_PASSWORD;
        $('#repeatpasswordspan').removeClass();
        $('#repeatpasswordspan').addClass('m-form__help m--font-danger');
        $('#repeatpasswordlabel').removeClass();
        $('#repeatpasswordlabel').addClass('m--font-danger');
    }

    private emailWarnings(): void {
        this.span_email = this.SPAN_DANGER_EMAIL;
        $('#emailspan').removeClass();
        $('#emailspan').addClass('m-form__help m--font-danger');
        $('#emaillabel').removeClass();
        $('#emaillabel').addClass('m--font-danger');
    }

    private phoneWarnings(): void {
        this.span_phone = this.SPAN_DANGER_PHONE;
        $('#phonespan').removeClass();
        $('#phonespan').addClass('m-form__help m--font-danger');
        $('#phonelabel').removeClass();
        $('#phonelabel').addClass('m--font-danger');
    }
}
