import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    constructor() { }

    public validateEmail(email: string): boolean {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    public validateNonEmpty(input_string: string): boolean {
        // string is empty contains no character maybe only whitespace or null
        let re = /\S/;
        return re.test(input_string);
    }

    public validateWhiteSpace(input_string: string): boolean {
        // string contains whitespace
        let re = /\s/;

        if (re.test(input_string)) {
            return false;
        }
        return true;
    }

    public validateInteger(input_number: string): boolean {
        let re = /^\d+$/;
        return re.test(input_number);
    }

    public validatePhone(input_number: string): boolean {
        let re = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
        return re.test(input_number);
    }

    public validateDate(input_string: string): boolean {
        // capture all the parts
        // let matches = input_string.match(/^(\d{2})\.(\d{2})\.(\d{4}) (\d{2}):(\d{2}):(\d{2})$/);
        //alt:
        let matches = input_string.match(/^(\d{2}).(\d{2}).(\d{4}).(\d{2}).(\d{2}).(\d{2})$/);
        // also matches 22/05/2013 11:23:22 and 22a0592013,11@23a22
        if (matches === null) {
            // invalid
            return false;
        } else {
            // now lets check the date sanity
            let year = parseInt(matches[3], 10);
            let month = parseInt(matches[2], 10) - 1; // months are 0-11
            let day = parseInt(matches[1], 10);
            let hour = parseInt(matches[4], 10);
            let minute = parseInt(matches[5], 10);
            let second = parseInt(matches[6], 10);
            let date = new Date(year, month, day, hour, minute, second);
            if (date.getFullYear() !== year
                || date.getMonth() != month
                || date.getDate() !== day
                || date.getHours() !== hour
                || date.getMinutes() !== minute
                || date.getSeconds() !== second
            ) {
                // invalid
                return false;
            } else {
                // valid
                return true;
            }
        }
    }

    public validateDateMoment(input_date: any): boolean {
        return moment(input_date).isValid();
    }
}
