import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { ConstantsService } from '../../services/utils/constants.service';
import { ValidationService } from '../../services/utils/validation.service';
import { UserService } from '../../services/user/user.service';
import { ApartmentService } from '../../services/apartment/apartment.service';
import Swal from 'sweetalert2';

declare var $: any;
declare var mUtil: any;
declare var mApp: any;

@Component({
    selector: 'app-user-manage',
    templateUrl: './user-manage.component.html',
    styleUrls: ['./user-manage.component.css']
})
export class UserManageComponent implements OnInit {
    public allUsers: any[] = [];
    private datatable: any;
    public selectedUsers: any[] = [];
    public currentSelectedUser: any = {};
    public avatar: string = '';
    private file: File = null;
    private readonly AVATAR_PLACEHOLDER: string = 'Choose file';

    private readonly SPAN_NORMAL_NAME: string = 'Please enter full name';
    private readonly SPAN_DANGER_NAME: string = 'Name is not correct!';
    private readonly SPAN_NORMAL_USERNAME: string = 'Please enter username';
    private readonly SPAN_DANGER_USERNAME: string = 'Username is not correct!';
    private readonly SPAN_NORMAL_PASSWORD: string = 'Please enter password';
    private readonly SPAN_DANGER_PASSWORD: string = 'Password doesnot match!';
    private readonly SPAN_NORMAL_REPEAT_PASSWORD: string = 'Please confirm password';
    private readonly SPAN_DANGER_REPEAT_PASSWORD: string = 'Password doesnot match!';
    private readonly SPAN_NORMAL_EMAIL: string = 'Please enter email';
    private readonly SPAN_DANGER_EMAIL: string = 'Email is not in correct format!';
    private readonly SPAN_NORMAL_PHONE: string = 'Please enter phone';
    private readonly SPAN_DANGER_PHONE: string = 'Phone is not in correct format!';

    public span_name: string;
    public span_username: string;
    public span_password: string;
    public span_repeat_password: string;
    public span_email: string;
    public span_phone: string;

    public isDeveloper: boolean = false;
    public isSuper: boolean = false;
    public isAdmin: boolean = false;
    public allClients: any[] = [];
    public allApartments: string[] = [];
    private clientsLoaded: boolean = false;

    @ViewChildren('ngForRendred') ngForList: QueryList<any>;

    constructor(private userService: UserService, private clientService: ClientService,
        private constantsService: ConstantsService, private validationService: ValidationService,
        private apartmentService: ApartmentService) { }

    ngOnInit() {
        this.avatar = this.AVATAR_PLACEHOLDER;

        mApp.block('#m_table_1', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...'
        });

        mApp.block('#m_modal_2 .modal-content', {
            overlayColor: '#000000',
            type: 'loader',
            state: 'success',
            message: 'Please wait...'
        });

        this.clearWarnings();
        this.getAllUsers();
        this.userService.getMe().subscribe(
            (response) => {
                // if reached here, it means the response is success
                let me = response.value;
                if (me.role == this.constantsService.ROLE_DEVELOPER) {
                    this.isDeveloper = true;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_SUPER_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = true;
                    this.isAdmin = true;
                }

                if (me.role == this.constantsService.ROLE_ADMIN) {
                    this.isDeveloper = false;
                    this.isSuper = false;
                    this.isAdmin = true;
                }

                if (this.isSuper) {
                    this.getAllClients();
                } else {
                    this.getAllApartments();
                }
            },
            (err) => {
                // if reached here, it means the response is error
            });
    }

    ngAfterViewInit() {
        this.ngForList.changes.subscribe(t => {
            this.ngForRendredCallback();
        });

        $('#phone').intlTelInput({
            initialCountry: "auto",
            geoIpLookup: function(callback) {
                $.get('https://ipinfo.io', function() { }, "jsonp").always(function(resp) {
                    var countryCode = (resp && resp.country) ? resp.country : "";
                    callback(countryCode);
                });
            },
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.4.0/js/utils.js" // just for formatting/placeholders etc
        });

        // fix the width problem of intl-tel-input,
        // applying this in css has no effect because textbox is converted after view init
        $('.intl-tel-input').css("width", "100%");

        // basic
        $('#m_select2_1').select2({
            placeholder: "Select role",
            allowClear: false,
            width: '100%'
        });
        $('#m_select2_4').select2({
            placeholder: "Select profile",
            allowClear: false,
            width: '100%'
        });
    }

    ngForRendredCallback() {
        if (!this.clientsLoaded) {
            $('#m_select2_2').select2({
                placeholder: "Select client",
                allowClear: true,
                width: '100%'
            });
            $('#m_select2_2').val(null).trigger('change'); // this will set selection to first item
        }
        let self = this;
        $('#m_select2_3').select2({
            placeholder: "Select apartments",
            allowClear: true,
            width: '100%'
        });
        $('#m_select2_3').val(null).trigger('change'); // this will set selection to first item

        $('#m_select2_2').on('select2:select', function(e) {
            // var data = e.params.data;
            mApp.block('#m_modal_2 .modal-content', {
                overlayColor: '#000000',
                type: 'loader',
                state: 'success',
                message: 'Please wait...'
            });
            let client_name = $('#m_select2_2').select2('data')[0].text;
            self.getAllClientApartments(client_name);
        });
    }

    getAllUsers(): void {
        this.userService.getAllUsers().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allUsers = response.value;
                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            },
            (err) => {
                // if reached here, it means the response is error
                this.allUsers = [];
                if (this.datatable) {
                    this.datatable.clear();
                    this.datatable.destroy();
                    // this.datatable.draw();
                }
                this.InitTable();
                mApp.unblock('#m_table_1');
            });
    }

    getAllClients(): void {
        this.clientService.getAllClients().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allClients = response.value;
                mApp.unblock('#m_modal_2 .modal-content');
            },
            (err) => {
                // if reached here, it means the response is error
                this.allClients = [];
                mApp.unblock('#m_modal_2 .modal-content');
            });
    }

    getAllApartments(): void {
        this.apartmentService.getAllApartments().subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allApartments = response.value;
                mApp.unblock('#m_modal_2 .modal-content');
            },
            (err) => {
                // if reached here, it means the response is error
                this.allApartments = [];
                mApp.unblock('#m_modal_2 .modal-content');
            });
    }

    getAllClientApartments(client: string): void {
        this.apartmentService.getApartmentByClient(client).subscribe(
            (response) => {
                // if reached here, it means the response is success
                this.allApartments = response.value;
                mApp.unblock('#m_modal_2 .modal-content');
            },
            (err) => {
                // if reached here, it means the response is error
                this.allApartments = [];
                mApp.unblock('#m_modal_2 .modal-content');
            });
    }

    private clearWarnings(): void {
        this.span_name = this.SPAN_NORMAL_NAME;
        this.span_username = this.SPAN_NORMAL_USERNAME;
        this.span_password = this.SPAN_NORMAL_PASSWORD;
        this.span_repeat_password = this.SPAN_NORMAL_REPEAT_PASSWORD;
        this.span_email = this.SPAN_NORMAL_EMAIL;
        this.span_phone = this.SPAN_NORMAL_PHONE;

        $('#namespan').removeClass();
        $('#namespan').addClass('m-form__help');
        $('#namelabel').removeClass();
        $('#usernamespan').removeClass();
        $('#usernamespan').addClass('m-form__help');
        $('#usernamelabel').removeClass();
        $('#passwordspan').removeClass();
        $('#passwordspan').addClass('m-form__help');
        $('#passwordlabel').removeClass();
        $('#repeatpasswordspan').removeClass();
        $('#repeatpasswordspan').addClass('m-form__help');
        $('#repeatpasswordlabel').removeClass();
        $('#emailspan').removeClass();
        $('#emailspan').addClass('m-form__help');
        $('#emaillabel').removeClass();
        $('#phonespan').removeClass();
        $('#phonespan').addClass('m-form__help');
        $('#phonelabel').removeClass();
    }

    public fileChange(event) {
        let fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.file = fileList[0];
            this.avatar = fileList[0].name;
        }
    }

    private nameWarnings(): void {
        this.span_name = this.SPAN_DANGER_NAME;
        $('#namespan').removeClass();
        $('#namespan').addClass('m-form__help m--font-danger');
        $('#namelabel').removeClass();
        $('#namelabel').addClass('m--font-danger');
    }

    private usernameWarnings(): void {
        this.span_username = this.SPAN_DANGER_USERNAME;
        $('#usernamespan').removeClass();
        $('#usernamespan').addClass('m-form__help m--font-danger');
        $('#usernamelabel').removeClass();
        $('#usernamelabel').addClass('m--font-danger');
    }

    private passwordWarnings(): void {
        this.span_password = this.SPAN_DANGER_PASSWORD;
        $('#passwordspan').removeClass();
        $('#passwordspan').addClass('m-form__help m--font-danger');
        $('#passwordlabel').removeClass();
        $('#passwordlabel').addClass('m--font-danger');
    }

    private repeatpasswordWarnings(): void {
        this.span_repeat_password = this.SPAN_DANGER_REPEAT_PASSWORD;
        $('#repeatpasswordspan').removeClass();
        $('#repeatpasswordspan').addClass('m-form__help m--font-danger');
        $('#repeatpasswordlabel').removeClass();
        $('#repeatpasswordlabel').addClass('m--font-danger');
    }

    private emailWarnings(): void {
        this.span_email = this.SPAN_DANGER_EMAIL;
        $('#emailspan').removeClass();
        $('#emailspan').addClass('m-form__help m--font-danger');
        $('#emaillabel').removeClass();
        $('#emaillabel').addClass('m--font-danger');
    }

    private phoneWarnings(): void {
        this.span_phone = this.SPAN_DANGER_PHONE;
        $('#phonespan').removeClass();
        $('#phonespan').addClass('m-form__help m--font-danger');
        $('#phonelabel').removeClass();
        $('#phonelabel').addClass('m--font-danger');
    }

    private validate(user: any): boolean {
        let validation: boolean = true;
        // Name
        if (!(this.validationService.validateNonEmpty(user.name))) {
            validation = false;
            this.nameWarnings();
        }
        // Username
        if (!(this.validationService.validateNonEmpty(user.username)) ||
            !(this.validationService.validateWhiteSpace(user.username))) {
            validation = false;
            this.usernameWarnings();
        }
        // Password
        if (!(this.validationService.validateNonEmpty(user.password)) ||
            !(this.validationService.validateWhiteSpace(user.password)) ||
            (user.password != user.repeat_password)) {
            validation = false;
            this.passwordWarnings();
            this.repeatpasswordWarnings();
        }
        // Email
        if (!(this.validationService.validateEmail(user.email))) {
            validation = false;
            this.emailWarnings();
        }

        // Phone
        if (!$("#phone").intlTelInput("isValidNumber")) {
            validation = false;
            this.phoneWarnings();
        }
        return validation;
    }

    public submitEdit(): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            )

            return;
        }
        this.clearWarnings();
        let btn = $('#submit_button');
        btn.addClass('m-loader m-loader--right m-loader--light').attr('disabled', true);

        let role_data = $('#m_select2_1').select2('data')[0];
        let role = '';
        if (role_data.id == 0) {
            role = this.constantsService.ROLE_DEVELOPER;
        } else if (role_data.id == 1) {
            role = this.constantsService.ROLE_SUPER_ADMIN;
        } else if (role_data.id == 2) {
            role = this.constantsService.ROLE_ADMIN;
        } else if (role_data.id == 3) {
            role = this.constantsService.ROLE_USER;
        }

        let profile_data = $('#m_select2_4').select2('data')[0];
        let profile = '';
        if (profile_data.id == 0) {
            profile = this.constantsService.PROFILE_CURRENT;
        } else if (profile_data.id == 1) {
            profile = this.constantsService.PROFILE_BASIC;
        } else if (profile_data.id == 2) {
            profile = this.constantsService.PROFILE_PROFESSIONAL;
        } else if (profile_data.id == 3) {
            profile = this.constantsService.PROFILE_ADVANCED;
        }

        // let detailed = $('#phone').intlTelInput("getSelectedCountryData");
        let isoCountry = $('#phone').intlTelInput("getSelectedCountryData").iso2;
        let nameCountry = $('#phone').intlTelInput("getSelectedCountryData").name;
        let fullNumber = $("#phone").intlTelInput("getNumber");
        // let dialCodeCountry = $('#phone').intlTelInput("getSelectedCountryData").dialCode;
        // let isPhoneValid = $("#phone").intlTelInput("isValidNumber");

        let client = (this.isSuper) ? $('#m_select2_2').select2('data')[0].text : null;
        let apartments = $('#m_select2_3').select2('data');
        let selected_apartments = [];
        for (let i = 0; i < apartments.length; i++) {
            selected_apartments.push(apartments[i].text);
        }

        let user = {
            'id': this.currentSelectedUser.id,
            'username': this.currentSelectedUser.username,
            'password': this.currentSelectedUser.password,
            'repeat_password': this.currentSelectedUser.repeat_password,
            'name': this.currentSelectedUser.name,
            'email': this.currentSelectedUser.email,
            'role': role,
            'profile': profile,
            'phone': fullNumber,
            'country': nameCountry,
            'countryIso': isoCountry,
            'client': client,
            'apartments': selected_apartments
        }

        console.log(user);

        btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);

        if (this.validate(user)) {
            // put the user
            this.userService.putUser(user, this.file).subscribe(
                (response) => {
                    // if reached here, it means the response is success
                    this.cancelEdit();
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                    this.getAllUsers();
                    $('#m_modal_2').modal('hide');
                },
                (err) => {
                    Swal(
                        'Oops...',
                        err._body,
                        'error'
                    );
                    // if reached here, it means the response is error
                    btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
                });
        } else {
            btn.removeClass('m-loader m-loader--right m-loader--light').attr('disabled', false);
        }
    }

    public cancelEdit(): void {
        this.currentSelectedUser = {};
        this.file = null;
        this.avatar = this.AVATAR_PLACEHOLDER;
        $('#m_select2_1').val(0).trigger('change');     // this will set selection to first item
        $('#m_select2_2').val(null).trigger('change');  // this will clear selection
        this.clearWarnings();
    }

    InitTable(): void {
        let self = this;
        // begin first table
        this.datatable = $('#m_table_1').DataTable({
            data: this.allUsers,
            responsive: true,
            paging: true,
            //== DOM Layout settings
            dom: "<'row'<'col-sm-12'f>>" +
                "<'row'<'col-sm-12'tr>>" +
                "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7 dataTables_pager'lp>>",

            pageLength: 10,

            language: {
                'lengthMenu': 'Display _MENU_',
            },

            //== Order settings
            order: [[1, 'desc']],

            headerCallback: function(thead, data, start, end, display) {
                thead.getElementsByTagName('th')[0].innerHTML = `
                        <label class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                            <input type="checkbox" value="" class="m-group-checkable">
                            <span></span>
                        </label>`;
            },

            columnDefs: [
                {
                    targets: 0,
                    data: "id",
                    width: '30px',
                    className: 'dt-right',
                    orderable: false,
                    render: function(data, type, full, meta) {
                        return `
                            <label id="` + full.id + `" class="m-checkbox m-checkbox--single m-checkbox--solid m-checkbox--brand">
                                <input type="checkbox" value="" class="m-checkable">
                                <span></span>
                            </label>`;
                    },
                },
                {
                    targets: 1,
                    data: "avatar",
                    title: "Avatar",
                    visible: false
                },
                {
                    targets: 2,
                    data: "name",
                    title: "Name",
                    visible: false
                },
                {
                    targets: 3,
                    data: "username",
                    title: "Username",
                    visible: false
                },
                {
                    targets: 4,
                    title: 'User',
                    orderable: false,
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        var output;
                        if (full.avatar == null) {
                            // No avatar
                            var stateNo = mUtil.getRandomInt(0, 7);
                            var states = [
                                'success',
                                'brand',
                                'danger',
                                'accent',
                                'warning',
                                'metal',
                                'primary',
                                'info'];
                            var state = states[stateNo];

                            output = `
                                              <div class="m-card-user m-card-user--sm">
                                                  <div class="m-card-user__pic">
                                                      <div class="m-card-user__no-photo m--bg-fill-` + state + `"><span>` + full.name.substring(0, 1) + `</span></div>
                                                  </div>
                                                  <div class="m-card-user__details">
                                                      <span class="m-card-user__name">` + full.name + `</span>
                                                      <a href="javascript:void(0);" class="m-card-user__email m-link">` + full.username + `</a>
                                                  </div>
                                              </div>`;
                        } else {
                            output = `
                                            <div class="m-card-user m-card-user--sm">
                                                <div class="m-card-user__pic">
                                                    <img src="` + self.constantsService.STATIC_URL + '/avatar/' + full.avatar + `" class="m--img-rounded m--marginless" alt="photo">
                                                </div>
                                                <div class="m-card-user__details">
                                                    <span class="m-card-user__name">` + full.name + `</span>
                                                    <a href="" class="m-card-user__email m-link">` + full.username + `</a>
                                                </div>
                                            </div>`;
                        }

                        return output;
                    }
                },
                {
                    targets: 5,
                    title: 'Email',
                    data: "email",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        return '<a class="m-link" href="mailto:' + data + '">' + data + '</a>';
                    }
                },
                {
                    targets: 6,
                    title: 'Phone',
                    data: "phone",
                    className: "text-center"
                },
                {
                    targets: 7,
                    title: 'Role',
                    data: "role",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        let location: number = 0;;
                        if (data == self.constantsService.ROLE_DEVELOPER) {
                            location = 0;
                        } else if (data == self.constantsService.ROLE_SUPER_ADMIN) {
                            location = 1;
                        } else if (data == self.constantsService.ROLE_ADMIN) {
                            location = 2;
                        } else if (data == self.constantsService.ROLE_USER) {
                            location = 3;
                        } else {
                            location = 4;
                        }
                        var status = {
                            0: { 'title': 'Developer', 'state': 'success' },
                            1: { 'title': 'Super Admin', 'state': 'primary' },
                            2: { 'title': 'Admin', 'state': 'accent' },
                            3: { 'title': 'User', 'state': 'metal' },
                            4: { 'title': 'User', 'state': 'warning' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        return '<span class="m-badge m-badge--' + status[location].state + ' m-badge--dot"></span>&nbsp;' +
                            '<span class="m--font-bold m--font-' + status[location].state + '">' + status[location].title + '</span>';
                    }
                },
                {
                    targets: 8,
                    title: 'Profile',
                    data: "profile",
                    className: "text-center",
                    render: function(data, type, full, meta) {
                        let location: number = 0;;
                        if (data == self.constantsService.PROFILE_CURRENT) {
                            location = 0;
                        } else if (data == self.constantsService.PROFILE_BASIC) {
                            location = 1;
                        } else if (data == self.constantsService.PROFILE_PROFESSIONAL) {
                            location = 2;
                        } else if (data == self.constantsService.PROFILE_ADVANCED) {
                            location = 3;
                        }
                        var status = {
                            0: { 'title': 'Current', 'state': 'success' },
                            1: { 'title': 'Basic', 'state': 'primary' },
                            2: { 'title': 'Professional', 'state': 'accent' },
                            3: { 'title': 'Advanced', 'state': 'metal' },
                        };
                        // if (typeof status[data] === 'undefined') {
                        //     return data;
                        // }
                        return '<span class="m-badge m-badge--' + status[location].state + ' m-badge--dot"></span>&nbsp;' +
                            '<span class="m--font-bold m--font-' + status[location].state + '">' + status[location].title + '</span>';
                    }
                },
                {
                    targets: 9,
                    title: "Country",
                    data: "countryIso",
                    className: "text-center",
                },
                {
                    targets: 10,
                    title: "Client",
                    data: "client",
                    className: "text-center",
                },
                {
                    targets: 11,
                    title: 'Actions',
                    orderable: false,
                    className: "text-center",
                    width: '100px',
                    render: function(data, type, full, meta) {
                        return `
                            <span class="dropdown">
                                <a href="#" class="btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" data-toggle="dropdown" aria-expanded="true">
                                  <i class="la la-ellipsis-h"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a id="` + full.id + `" class="dropdown-item" href="javascript:void(0);"><i class="la la-edit"></i> Edit Details</a>
                                    <a id="` + full.id + `" class="dropdown-item" href="javascript:void(0);"><i class="la la-eye"></i> Show Details</a>
                                </div>
                            </span>
                            <a href="javascript:void(0);" id="`+ full.id + `" class="m-portlet__nav-link btn m-btn m-btn--hover-brand m-btn--icon m-btn--icon-only m-btn--pill" title="Remove">
                              <i class="la la-trash"></i>
                            </a>`;
                    },
                }
            ],
        });

        this.datatable.on('change', '.m-group-checkable', function() {
            var set = $(this).closest('table').find('td:first-child .m-checkable');
            var checked = $(this).is(':checked');

            $(set).each(function() {
                if (checked) {
                    $(this).prop('checked', true);
                    $(this).closest('tr').addClass('active');
                }
                else {
                    $(this).prop('checked', false);
                    $(this).closest('tr').removeClass('active');
                }
            });

            if (checked) {
                for (let i = 0; i < self.allUsers.length; i++) {
                    self.selectedUsers[i] = self.allUsers[i].id;
                }
            } else {
                self.selectedUsers = [];
            }
        });

        this.datatable.on('change', 'tbody tr .m-checkbox', function() {
            let id = $(this).attr('id');

            let isChecked = $(this).parents('tr').find('td:first-child .m-checkable').is(':checked');

            if (isChecked) {
                //return index of element, if not found it will return -1
                let found = $.inArray(id, self.selectedUsers);
                if (found < 0) {
                    // Element was not found, add it.
                    self.selectedUsers.push(id);
                }
            } else {
                // return index of element, if not found it will return -1
                let found = $.inArray(id, self.selectedUsers);
                if (found >= 0) {
                    // Element was found, remove it.
                    self.selectedUsers.splice(found, 1);
                }
            }
        });

        $('#m_table_1').on('click', 'a.m-portlet__nav-link', function() {
            let id = $(this).attr('id');
            self.DeleteUser(id);
        });

        $('#m_table_1').on('click', 'div.dropdown-menu > a.dropdown-item', function() {
            if ($(this).children('.la-edit').length > 0) {
                // edit is clicked
                let id = $(this).attr('id');
                self.userService.getUser(id).subscribe(
                    (response) => {
                        if (response.value != null) {
                            self.currentSelectedUser = response.value;
                            self.currentSelectedUser.password = '';
                            self.currentSelectedUser.repeat_password = '';

                            if (self.currentSelectedUser.role == self.constantsService.ROLE_DEVELOPER) {
                                $('#m_select2_1').val(0).trigger('change'); // this will set selection to first item
                            } else if (self.currentSelectedUser.role == self.constantsService.ROLE_SUPER_ADMIN) {
                                $('#m_select2_1').val(1).trigger('change'); // this will set selection to second item
                            } else if (self.currentSelectedUser.role == self.constantsService.ROLE_ADMIN) {
                                $('#m_select2_1').val(2).trigger('change'); // this will set selection to third item
                            } else if (self.currentSelectedUser.role == self.constantsService.ROLE_USER) {
                                $('#m_select2_1').val(3).trigger('change'); // this will set selection to fourth item
                            }

                            $("#m_select2_2").val($("#m_select2_2 option:contains('" +
                                self.currentSelectedUser.client + "')").val()).trigger('change');

                            self.clientsLoaded = true;
                            self.getAllClientApartments(self.currentSelectedUser.client);

                            $('#m_modal_2').modal('show');
                        }
                    }, (err) => {
                        self.getAllUsers();
                        Swal(
                            'Cancelled',
                            'Error occured!',
                            'error'
                        )
                    }
                );
            } else if ($(this).children('.la-eye').length > 0) {
                // show is clicked
                let id = $(this).attr('id');
                self.userService.getUser(id).subscribe(
                    (response) => {
                        if (response.value != null) {
                            self.currentSelectedUser = response.value;
                            if (self.currentSelectedUser.avatar == null) {
                                self.currentSelectedUser.avatarExist = false;
                                var stateNo = mUtil.getRandomInt(0, 7);
                                var states = [
                                    'success',
                                    'brand',
                                    'danger',
                                    'accent',
                                    'warning',
                                    'metal',
                                    'primary',
                                    'info'];
                                var state = states[stateNo];
                                $('#nophoto').removeClass();
                                $('#nophoto').addClass('m-card-user__no-photo m--bg-fill-' + state);
                                self.currentSelectedUser.subname = self.currentSelectedUser.name.substring(0, 1);
                            } else {
                                self.currentSelectedUser.avatarExist = true;
                            }

                            if (self.currentSelectedUser.role == self.constantsService.ROLE_DEVELOPER) {
                                self.currentSelectedUser.role = 'Developer';
                            } else if (self.currentSelectedUser.role == self.constantsService.ROLE_SUPER_ADMIN) {
                                self.currentSelectedUser.role = 'Super Admin';
                            } else if (self.currentSelectedUser.role == self.constantsService.ROLE_ADMIN) {
                                self.currentSelectedUser.role = 'Admin';
                            } else if (self.currentSelectedUser.role == self.constantsService.ROLE_USER) {
                                self.currentSelectedUser.role = 'User';
                            }

                            $('#m_modal_1').modal('show');
                        }
                    }, (err) => {
                        self.getAllUsers();
                        Swal(
                            'Cancelled',
                            'Error occured!',
                            'error'
                        )
                    }
                );
            }
        });
    }

    public DeleteUser(id: string): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            )

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover user again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                self.userService.deleteUser(id).subscribe(
                    (response) => {
                        self.getAllUsers();
                        // Swal(
                        //     'Deleted!',
                        //     'User has been deleted.',
                        //     'success'
                        // );
                    }, (err) => {
                        self.getAllUsers();
                        Swal(
                            'Cancelled',
                            err._body,
                            'error'
                        )
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        })
    }

    public DeleteUsers(): void {
        if (!this.isAdmin) {
            Swal(
                'Unauthorized',
                'You are not allowed to perform this operation',
                'error'
            )

            return;
        }
        let self = this;
        Swal({
            title: 'Are you sure?',
            text: 'You will not be able to recover those users again!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
        }).then((result) => {
            if (result.value) {
                self.userService.deleteUsers(self.selectedUsers).subscribe(
                    (response) => {
                        self.getAllUsers();
                        // Swal(
                        //     'Deleted!',
                        //     'User has been deleted.',
                        //     'success'
                        // );
                    }, (err) => {
                        self.getAllUsers();
                        Swal(
                            'Cancelled',
                            'Error occured and users were not deleted',
                            'error'
                        )
                    }
                );
                // For more information about handling dismissals please visit
                // https://sweetalert2.github.io/#handling-dismissals
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                // Swal(
                //     'Cancelled',
                //     'Your imaginary file is safe :',
                //     'error'
                // )
            }
        })
    }
}
