import { Component, OnInit, AfterViewInit, OnDestroy, Renderer2 } from '@angular/core';
import { LayoutService } from '../../services/utils/layout.service';

declare var $: any;
declare var mLayout: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(private layoutService: LayoutService, private renderer: Renderer2) { }

    ngOnInit() { }

    ngAfterViewInit() {
        // Update the background
        $(document.body).removeClass();
        $(document.body).addClass('m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default');
        // this.renderer.addClass(document.body, 'm-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default');

        mLayout.init();
    }

    ngOnDestroy() {
        $(document.body).removeClass();
        // this.renderer.removeClass(document.body, 'm-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--fixed m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default');
    }

}
