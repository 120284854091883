import { Injectable } from '@angular/core';
import { TokenService } from '../token/token.service';
import { ConstantsService } from '../utils/constants.service';
import {
    Router, CanActivate, ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoginGuardSuperService implements CanActivate {

    constructor(private tokenService: TokenService, private constantsService: ConstantsService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.tokenService.authenticate() == null) {
            this.router.navigateByUrl('/login');
            return false;
        }
        return this.tokenService.authenticate().pipe(map(
            (response) => {
                // if reached here, it means the response is success
                let role: string = response.value.role;
                if (role == this.constantsService.ROLE_DEVELOPER ||
                    role == this.constantsService.ROLE_SUPER_ADMIN) {
                    return true;
                } else {
                    this.router.navigateByUrl('/login');
                    return false;
                }
            },
            (err) => {
                // if reached here, it means the response is error
                this.router.navigateByUrl('/login');
                return false;
            }
        ));
    }
}
