import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RoutingModule } from './app.routing';

import { Ng2Webstorage } from 'ngx-webstorage';
import { NgxMaskModule } from 'ngx-mask';

import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ErrorComponent } from './components/error/error.component';
import { FaqComponent } from './components/faq/faq.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ScrollTopComponent } from './components/scroll-top/scroll-top.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SupportComponent } from './components/support/support.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { UserAddComponent } from './components/user-add/user-add.component';
import { UserManageComponent } from './components/user-manage/user-manage.component';
import { ClientAddComponent } from './components/client-add/client-add.component';
import { ClientManageComponent } from './components/client-manage/client-manage.component';
import { ApartmentManageComponent } from './components/apartment-manage/apartment-manage.component';
import { ApartmentAddComponent } from './components/apartment-add/apartment-add.component';
import { RoomManageComponent } from './components/room-manage/room-manage.component';
import { RoomAddComponent } from './components/room-add/room-add.component';
import { GatewayFirmwareComponent } from './components/gateway-firmware/gateway-firmware.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { GatewayComponent } from './components/gateway/gateway.component';

@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        ErrorComponent,
        FaqComponent,
        FooterComponent,
        HomeComponent,
        LoginComponent,
        PrivacyComponent,
        ProfileComponent,
        ScrollTopComponent,
        SidebarComponent,
        SupportComponent,
        TopMenuComponent,
        UserAddComponent,
        UserManageComponent,
        ClientAddComponent,
        ClientManageComponent,
        ApartmentManageComponent,
        ApartmentAddComponent,
        RoomManageComponent,
        RoomAddComponent,
        GatewayFirmwareComponent,
        NotificationsComponent,
        GatewayComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        RoutingModule,
        Ng2Webstorage,
        NgxMaskModule.forRoot()
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
