import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs';
import { mergeMap, map, delay, catchError } from 'rxjs/operators';
import { ConstantsService } from '../utils/constants.service';
import { TokenService } from '../token/token.service';

@Injectable({
    providedIn: 'root'
})
export class RoomService {

    constructor(private http: Http, private tokenService: TokenService,
        private constnatsService: ConstantsService) { }

    postRoom(room: any): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                formData.append('name', room.name);
                formData.append('info', room.info);
                formData.append('apartmentName', room.apartmentName);
                if (room.client) {
                    formData.append('client', room.client);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.post(self.constnatsService.API_CORE_URL + '/api/room/', formData, options)
                    .pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    putRoom(room: any): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                formData.append('name', room.name);
                formData.append('info', room.info);
                formData.append('apartmentName', room.apartmentName);
                if (room.client) {
                    formData.append('client', room.client);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.put(self.constnatsService.API_CORE_URL + '/api/room/' + room.id, formData, options)
                    .pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getAllRooms(): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/room/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteRoom(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/room/' + id,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    deleteRooms(id: Array<string>): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let formData: FormData = new FormData();
                for (let i = 0; i < id.length; i++) {
                    formData.append('id[]', id[i]);
                }
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({
                    headers: headers,
                    body: formData
                });
                return self.http.delete(self.constnatsService.API_CORE_URL + '/api/room/',
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }

    getRoom(id: string): Observable<any> {
        var self = this;
        var chainedObservable$ = this.tokenService.getToken()
            .pipe(mergeMap(function(token_response) {
                let headers = new Headers();
                headers.append('Content-Type', 'application/x-www-form-urlencoded');
                headers.append('Accept', 'application/json');
                if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                if (token_response) {
                    let access_token = token_response.access_token;
                    headers.append('Authorization', 'Bearer ' + access_token);
                }
                let options = new RequestOptions({ headers: headers });
                return self.http.get(self.constnatsService.API_CORE_URL + '/api/room/' + id,
                    options).pipe(map(res => res.json()));
            }));

        return chainedObservable$;
    }
}
