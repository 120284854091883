import { Injectable } from '@angular/core';

declare var $: any;
declare var mScrollTop: any;
declare var mUtil: any;
declare var mHeader: any;
declare var mOffcanvas: any;
declare var mMenu: any;
declare var mQuicksearch: any;
declare var mToggle: any;
declare var mPortlet: any;
declare var Cookies: any;

@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    private scrollTop: any;
    private header: any;
    private horMenuOffcanvas: any;
    private horMenu: any;
    private quicksearch: any;
    private asideMenuOffcanvas: any;
    private asideMenu: any;
    public asideLeftToggle: any;
    private mainPortlet: any;
    private initLeftAsideHideElement: any;

    constructor() { }

    private initStickyHeader(): void {
        var tmp;
        var headerEl = mUtil.get('m_header');
        var options: any = {
            offset: {},
            minimize: {}
        };

        if (mUtil.attr(headerEl, 'm-minimize-mobile') == 'hide') {
            options.minimize.mobile = {};
            options.minimize.mobile.on = 'm-header--hide';
            options.minimize.mobile.off = 'm-header--show';
        } else {
            options.minimize.mobile = false;
        }

        if (mUtil.attr(headerEl, 'm-minimize') == 'hide') {
            options.minimize.desktop = {};
            options.minimize.desktop.on = 'm-header--hide';
            options.minimize.desktop.off = 'm-header--show';
        } else {
            options.minimize.desktop = false;
        }

        if (tmp = mUtil.attr(headerEl, 'm-minimize-offset')) {
            options.offset.desktop = tmp;
        }

        if (tmp = mUtil.attr(headerEl, 'm-minimize-mobile-offset')) {
            options.offset.mobile = tmp;
        }

        this.header = new mHeader('m_header', options);
    }

    private initHorMenu(): void {
        // init aside left offcanvas
        this.horMenuOffcanvas = new mOffcanvas('m_header_menu', {
            overlay: true,
            baseClass: 'm-aside-header-menu-mobile',
            closeBy: 'm_aside_header_menu_mobile_close_btn',
            toggleBy: {
                target: 'm_aside_header_menu_mobile_toggle',
                state: 'm-brand__toggler--active'
            }
        });

        this.horMenu = new mMenu('m_header_menu', {
            submenu: {
                desktop: 'dropdown',
                tablet: 'accordion',
                mobile: 'accordion'
            },
            accordion: {
                slideSpeed: 200, // accordion toggle slide speed in milliseconds
                expandAll: false // allow having multiple expanded accordions in the menu
            }
        });
    }

    private initTopbar(): void {
        $('#m_aside_header_topbar_mobile_toggle').click(function() {
            $('body').toggleClass('m-topbar--on');
        });

        // Animated Notification Icon
        /*
        setInterval(function() {
            $('#m_topbar_notification_icon .m-nav__link-icon').addClass('m-animate-shake');
            $('#m_topbar_notification_icon .m-nav__link-badge').addClass('m-animate-blink');
        }, 3000);

        setInterval(function() {
            $('#m_topbar_notification_icon .m-nav__link-icon').removeClass('m-animate-shake');
            $('#m_topbar_notification_icon .m-nav__link-badge').removeClass('m-animate-blink');
        }, 6000);
        */
    }

    private initQuicksearch(): void {
        if ($('#m_quicksearch').length === 0) {
            return;
        }

        this.quicksearch = new mQuicksearch('m_quicksearch', {
            mode: mUtil.attr('m_quicksearch', 'm-quicksearch-mode'), // quick search type
            minLength: 1
        });

        //<div class="m-search-results m-search-results--skin-light"><span class="m-search-result__message">Something went wrong</div></div>

        this.quicksearch.on('search', function(the) {
            the.showProgress();

            // $.ajax({
            //     url: 'inc/api/quick_search.php',
            //     data: {
            //         query: the.query
            //     },
            //     dataType: 'html',
            //     success: function(res) {
            //         the.hideProgress();
            //         the.showResult(res);
            //     },
            //     error: function(res) {
            //         the.hideProgress();
            //         the.showError('Connection error. Pleae try again later.');
            //     }
            // });
        });
    }

    private initScrollTop(): void {
        this.scrollTop = new mScrollTop('m_scroll_top', {
            offset: 300,
            speed: 600
        });
    }

    private initLeftAside(): void {
        let self = this;
        // init aside left offcanvas
        var body = mUtil.get('body');
        var asideLeft = mUtil.get('m_aside_left');
        var asideOffcanvasClass = mUtil.hasClass(asideLeft, 'm-aside-left--offcanvas-default') ? 'm-aside-left--offcanvas-default' : 'm-aside-left';

        this.asideMenuOffcanvas = new mOffcanvas('m_aside_left', {
            baseClass: asideOffcanvasClass,
            overlay: true,
            closeBy: 'm_aside_left_close_btn',
            toggleBy: {
                target: 'm_aside_left_offcanvas_toggle',
                state: 'm-brand__toggler--active'
            }
        });

        //== Handle minimzied aside hover
        if (mUtil.hasClass(body, 'm-aside-left--fixed')) {
            var insideTm;
            var outsideTm;

            mUtil.addEvent(asideLeft, 'mouseenter', function() {
                if (outsideTm) {
                    clearTimeout(outsideTm);
                    outsideTm = null;
                }

                insideTm = setTimeout(function() {
                    if (mUtil.hasClass(body, 'm-aside-left--minimize') && mUtil.isInResponsiveRange('desktop')) {
                        mUtil.removeClass(body, 'm-aside-left--minimize');
                        mUtil.addClass(body, 'm-aside-left--minimize-hover');
                        self.asideMenu.scrollerUpdate();
                        self.asideMenu.scrollerTop();
                    }
                }, 300);
            });

            mUtil.addEvent(asideLeft, 'mouseleave', function() {
                if (insideTm) {
                    clearTimeout(insideTm);
                    insideTm = null;
                }

                outsideTm = setTimeout(function() {
                    if (mUtil.hasClass(body, 'm-aside-left--minimize-hover') && mUtil.isInResponsiveRange('desktop')) {
                        mUtil.removeClass(body, 'm-aside-left--minimize-hover');
                        mUtil.addClass(body, 'm-aside-left--minimize');
                        self.asideMenu.scrollerUpdate();
                        self.asideMenu.scrollerTop();
                    }
                }, 500);
            });
        }
    }

    private initLeftAsideMenu(): void {
        //== Init aside menu
        var menu = mUtil.get('m_ver_menu');
        var menuDesktopMode = (mUtil.attr(menu, 'm-menu-dropdown') === '1' ? 'dropdown' : 'accordion');

        var scroll;
        if (mUtil.attr(menu, 'm-menu-scrollable') === '1') {
            scroll = {
                height: function() {
                    if (mUtil.isInResponsiveRange('desktop')) {
                        return mUtil.getViewPort().height - parseInt(mUtil.css('m_header', 'height'));
                    }
                }
            };
        }

        this.asideMenu = new mMenu('m_ver_menu', {
            // vertical scroll
            scroll: scroll,

            // submenu setup
            submenu: {
                desktop: {
                    // by default the menu mode set to accordion in desktop mode
                    default: menuDesktopMode,
                    // whenever body has this class switch the menu mode to dropdown
                    state: {
                        body: 'm-aside-left--minimize',
                        mode: 'dropdown'
                    }
                },
                tablet: 'accordion', // menu set to accordion in tablet mode
                mobile: 'accordion' // menu set to accordion in mobile mode
            },

            //accordion setup
            accordion: {
                autoScroll: false, // enable auto scrolling(focus) to the clicked menu item
                expandAll: false // allow having multiple expanded accordions in the menu
            }
        });
    }

    private initLeftAsideToggle(): void {
        let self = this;
        if ($('#m_aside_left_minimize_toggle').length === 0) {
            return;
        }

        this.asideLeftToggle = new mToggle('m_aside_left_minimize_toggle', {
            target: 'body',
            targetState: 'm-brand--minimize m-aside-left--minimize',
            togglerState: 'm-brand__toggler--active'
        });

        // this.asideLeftToggle.on('toggle', function(toggle) {
        //     if (mUtil.get('main_portlet')) {
        //         self.mainPortlet.updateSticky();
        //     }
        //
        //     self.horMenu.pauseDropdownHover(800);
        //     self.asideMenu.pauseDropdownHover(800);
        //
        //     //== Remember state in cookie
        //     Cookies.set('sidebar_toggle_state', toggle.getState());
        //     // to set default minimized left aside use this cookie value in your
        //     // server side code and add "m-brand--minimize m-aside-left--minimize" classes to
        //     // the body tag in order to initialize the minimized left aside mode during page loading.
        // });
        //
        // this.asideLeftToggle.on('beforeToggle', function(toggle) {
        //     var body = mUtil.get('body');
        //     if (mUtil.hasClass(body, 'm-aside-left--minimize') === false && mUtil.hasClass(body, 'm-aside-left--minimize-hover')) {
        //         mUtil.removeClass(body, 'm-aside-left--minimize-hover');
        //     }
        // });
    }

    private initLeftAsideHide(): void {
        let self = this;
        if ($('#m_aside_left_hide_toggle').length === 0) {
            return;
        }

        this.initLeftAsideHideElement = new mToggle('m_aside_left_hide_toggle', {
            target: 'body',
            targetState: 'm-aside-left--hide',
            togglerState: 'm-brand__toggler--active'
        });

        this.initLeftAsideHideElement.on('toggle', function(toggle) {

            self.horMenu.pauseDropdownHover(800);
            self.asideMenu.pauseDropdownHover(800);

            //== Remember state in cookie
            Cookies.set('sidebar_hide_state', toggle.getState());
            // to set default minimized left aside use this cookie value in your
            // server side code and add "m-brand--minimize m-aside-left--minimize" classes to
            // the body tag in order to initialize the minimized left aside mode during page loading.
        });
    }

    public initMainPortlet(): void {
        let self = this;
        if (!mUtil.get('main_portlet')) {
            return;
        }

        this.mainPortlet = this.createMainPortlet();
        this.mainPortlet.initSticky();

        mUtil.addResizeHandler(function() {
            self.mainPortlet.updateSticky();
        });
    }

    private createMainPortlet(): any {
        return new mPortlet('main_portlet', {
            sticky: {
                offset: parseInt(mUtil.css(mUtil.get('m_header'), 'height')),
                zIndex: 90,
                position: {
                    top: function() {
                        return parseInt(mUtil.css(mUtil.get('m_header'), 'height'));
                    },
                    left: function() {
                        var left = parseInt(mUtil.css(mUtil.getByClass('m-content'), 'paddingLeft'));

                        if (mUtil.isInResponsiveRange('desktop')) {
                            //left += parseInt(mUtil.css(mUtil.get('m_aside_left'), 'width') );
                            if (mUtil.hasClass(mUtil.get('body'), 'm-aside-left--minimize')) {
                                left += 78; // need to use hardcoded width of the minimize aside
                            } else {
                                left += 255; // need to use hardcoded width of the aside
                            }
                        }

                        return left;
                    },
                    right: function() {
                        return parseInt(mUtil.css(mUtil.getByClass('m-content'), 'paddingRight'));
                    }
                }
            }
        });
    }

    init(): void {
        this.initHeader();
        this.initAside();
        this.initMainPortlet();
    }

    resetMainPortlet(): void {
        this.mainPortlet.destroySticky();
        this.mainPortlet = this.createMainPortlet();
        this.mainPortlet.initSticky();
    }

    initHeader(): void {
        this.initStickyHeader();
        this.initHorMenu();
        this.initTopbar();
        this.initQuicksearch();
        this.initScrollTop();
    }

    initAside(): void {
        let self = this;
        this.initLeftAside();
        this.initLeftAsideMenu();
        this.initLeftAsideToggle();
        this.initLeftAsideHide();

        if (this.asideLeftToggle) {
            this.asideLeftToggle.on('toggle', function(e) {
                //== Update sticky portlet
                if (self.mainPortlet) {
                    self.mainPortlet.updateSticky();
                }

                //== Reload datatable
                var datatables = $('.m-datatable');
                if (datatables) {
                    datatables.each(function() {
                        $(this).mDatatable('redraw');
                    });
                }
            })
        };
    }

    getAsideMenu(): void {
        return this.asideMenu;
    }

    closeMobileAsideMenuOffcanvas(): void {
        if (mUtil.isMobileDevice()) {
            this.asideMenuOffcanvas.hide();
        }
    }

    closeMobileHorMenuOffcanvas(): void {
        if (mUtil.isMobileDevice()) {
            this.horMenuOffcanvas.hide();
        }
    }
}
