import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { mergeMap, map, delay, catchError } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConstantsService } from '../utils/constants.service';
import { CustomQueryEncoderHelper } from '../../shared/helpers/custom-query-encoder-helper';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    private readonly jwtHelper: JwtHelperService = new JwtHelperService();

    constructor(private http: Http, private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService, private constnatsService: ConstantsService) { }

    setCredentials(save: boolean, username: string, password: string): void {
        var base64Creds = btoa(username + ":" + password);
        var auth = 'Basic ' + base64Creds;
        if (save) {
            this.localStorage.store('credentials', auth);
        } else {
            this.sessionStorage.store('credentials', auth);
        }
    }

    clearCredentials(): void {
        this.localStorage.clear('credentials');
        this.sessionStorage.clear('credentials');
    }

    getCredentials(): any {
        let auth = this.localStorage.retrieve('credentials');
        if (!auth) {
            // Try to find in session storage
            auth = this.sessionStorage.retrieve('credentials');
        }

        if (auth) {

            let res = auth.replace('Basic ', '');
            res = atob(res);
            let index = res.indexOf(':');
            let username = res.slice(0, index);
            let password = res.slice(index + 1, res.length);
            let ret = {
                username: username,
                password: password
            };
            return ret;
        }
        return null;
    }

    setToken(save: boolean, access_token: string, refresh_token?: string): void {
        if (save) {
            this.localStorage.store('access_token', access_token);
            if (refresh_token != null) {
                this.localStorage.store('refresh_token', refresh_token);
            }
        } else {
            this.sessionStorage.store('access_token', access_token);
            if (refresh_token != null) {
                this.sessionStorage.store('refresh_token', refresh_token);
            }
        }
    }

    clearToken(): void {
        this.localStorage.clear('access_token');
        this.localStorage.clear('refresh_token');
        this.sessionStorage.clear('access_token');
        this.sessionStorage.clear('refresh_token');
    }

    setClient(save: boolean, client_id: string, client_secret: string): void {
        if (save) {
            this.localStorage.store('client_id', client_id);
            this.localStorage.store('client_secret', client_secret);
        } else {
            this.sessionStorage.store('client_id', client_id);
            this.sessionStorage.store('client_secret', client_secret);
        }
    }

    clearClient(): void {
        this.localStorage.clear('client_id');
        this.localStorage.clear('client_secret');
        this.sessionStorage.clear('client_id');
        this.sessionStorage.clear('client_secret');
    }

    getClient(): any {
        let client_id = this.localStorage.retrieve('client_id');
        let client_secret = this.localStorage.retrieve('client_secret');
        if (!client_id) {
            // Try to find in session storage
            client_id = this.sessionStorage.retrieve('client_id');
        }
        if (!client_secret) {
            // Try to find in session storage
            client_secret = this.sessionStorage.retrieve('client_secret');
        }

        if (client_id != null && client_secret != null) {
            let ret = {
                client_id: client_id,
                client_secret: client_secret
            };
            return ret;
        }

        let ret_super = {
            client_id: this.constnatsService.CLIENT_ID,
            client_secret: this.constnatsService.CLIENT_SECRET
        };
        return ret_super;
    }

    getToken(): Observable<any> {
        let client: any = this.getClient();
        let credentials = this.getCredentials();
        let clientId: string = client.client_id;
        let clientSecret: string = client.client_secret;
        // first check whether there is already a token on local storage or session storage
        let access_token = this.localStorage.retrieve('access_token');
        let refresh_token = this.localStorage.retrieve('refresh_token');
        if (!access_token) {
            // Try to find in session storage
            access_token = this.sessionStorage.retrieve('access_token');
            refresh_token = this.sessionStorage.retrieve('refresh_token');
        }

        if (access_token != null && refresh_token != null) {
            // There is already a access_token stored
            // Now check the expiration of the access_token and convert into millis using .getTime()
            let expirationOfToken = this.jwtHelper.getTokenExpirationDate(access_token).getTime();
            // get current date in millis
            let currentDate = new Date().getTime();
            // Convert date to millis and check the threshold
            if (expirationOfToken - currentDate < this.constnatsService.THRESHOLD_REFRESH_TOKEN_MS) {
                // get new refresh token
                // User URLSearchParams to force Content-Type to be sent as x-www-form-urlencoded
                let body = new URLSearchParams('', new CustomQueryEncoderHelper());
                body.set('client_id', clientId);
                body.set('client_secret', clientSecret);
                body.set('grant_type', this.constnatsService.GRANT_TYPE_REFRESH_TOKEN);
                body.set('refresh_token', refresh_token);
                if(credentials != null) {
                    body.set('username', credentials.username);
                    body.set('password', credentials.password);
                }
                /////////////////////////////////////////
                let headers = new Headers();
                headers.append('Accept', 'application/json');
                headers.append('Cache-Control', 'no-cache');
                if(!this.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                {
                    headers.append('rejectUnauthorized', 'false');
                }
                /////////////////////////////////////////
                let options = new RequestOptions({ headers: headers }); // Create a request option
                /////////////////////////////////////////
                return this.http.post(this.constnatsService.IDENTITY_SERVER_URL + '/connect/token',
                    body, options).pipe(map(res => res.json()));
            } else {
                let response = {
                    access_token: access_token,
                    refresh_token: refresh_token
                }
                // return this token
                return new Observable<any>(observer => observer.next(response))
                    .pipe(map(res => res));
            }
        }

        // Get new token because there is no saved token on local storage or session storage
        if (credentials === null) {
            // // get client_credentials token
            // // User URLSearchParams to force Content-Type to be sent as x-www-form-urlencoded
            // let body = new URLSearchParams('', new CustomQueryEncoderHelper());
            // body.set('client_id', clientId);
            // body.set('client_secret', clientSecret);
            // body.set('grant_type', this.constnatsService.GRANT_TYPE_CLIENT_CREDENTIALS);
            // body.set('scope', this.constnatsService.REQUESTED_SCOPE);
            // /////////////////////////////////////////
            // let headers = new Headers();
            // headers.append('Accept', 'application/json');
            // headers.append('Cache-Control', 'no-cache');
            // if(!this.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
            // {
            //     headers.append('rejectUnauthorized', 'false');
            // }
            // /////////////////////////////////////////
            // let options = new RequestOptions({ headers: headers }); // Create a request option
            // /////////////////////////////////////////
            // return this.http.post(this.constnatsService.IDENTITY_SERVER_URL + '/connect/token',
            //     body, options).pipe(map(res => res.json()));

            // Now instead of getting the client token, we gonna return null token
            let Observable$: Observable<any> = new Observable(observer => {
                observer.next(null);
            });

            return Observable$;
        } else {
            // get password token
            // User URLSearchParams to force Content-Type to be sent as x-www-form-urlencoded
            let body = new URLSearchParams('', new CustomQueryEncoderHelper());
            body.set('client_id', clientId);
            body.set('client_secret', clientSecret);
            body.set('grant_type', this.constnatsService.GRANT_TYPE_USER_PASSWORD);
            body.set('username', credentials.username);
            body.set('password', credentials.password);
            /////////////////////////////////////////
            let headers = new Headers();
            headers.append('Accept', 'application/json');
            headers.append('Cache-Control', 'no-cache');
            if(!this.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
            {
                headers.append('rejectUnauthorized', 'false');
            }
            /////////////////////////////////////////
            let options = new RequestOptions({ headers: headers }); // Create a request option
            /////////////////////////////////////////
            return this.http.post(this.constnatsService.IDENTITY_SERVER_URL + '/connect/token',
                body, options).pipe(map(res => res.json()));
        }
    }

    getMyClient(access_token: string): Observable<any> {
        let headers = new Headers();
        headers.append('Authorization', 'Bearer ' + access_token);
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        if(!this.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
        {
            headers.append('rejectUnauthorized', 'false');
        }
        return this.http.get(this.constnatsService.API_CORE_URL + '/api/user/myclient', {
            headers: headers
        }).pipe(map(res => res.json()));

    }

    authenticate(save?: boolean, username?: string, password?: string): Observable<any> {
        if (username != null && password != null) {
            if (save == null) {
                save = false;
            }
            this.setCredentials(save, username, password);
        }
        var self = this;
        var chainedObservable$ = this.getToken()
            .pipe(mergeMap(function(token_response: any): Observable<any> {
                let access_token = token_response.access_token;
                return self.getMyClient(access_token).pipe(mergeMap(function(my_client_response: any): Observable<any> {
                    let client = my_client_response.value;
                    let clientId: string = client.clientId;
                    let clientSecret: string = client.clientSecrets[0].value;
                    self.setClient(save, clientId, clientSecret);
                    return self.getToken().pipe(mergeMap(function(final_token_response: any): Observable<any> {
                        let final_access_token = final_token_response.access_token;
                        // Check if there is refresh token on the JSON response
                        if (final_token_response.hasOwnProperty('refresh_token')) {
                            let refresh_token = final_token_response.refresh_token;
                            self.setToken(save, final_access_token, refresh_token);
                        } else {
                            self.setToken(save, final_access_token);
                        }
                        let headers = new Headers();
                        headers.append('Authorization', 'Bearer ' + final_access_token);
                        headers.append('Content-Type', 'application/x-www-form-urlencoded');
                        if(!self.constnatsService.REJECT_UNAUTHORIZED_SSL_CERTIFICATES)
                        {
                            headers.append('rejectUnauthorized', 'false');
                        }
                        return self.http.get(self.constnatsService.API_CORE_URL + '/api/user/me', {
                            headers: headers
                        }).pipe(map(res => res.json()));
                    }));
                }));
            }));

        return chainedObservable$;
    }
}
